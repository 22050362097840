// import React, { useEffect, useState, useMemo } from "react";
// import { ComposableMap, Geographies, Geography } from "react-simple-maps";
// import { Tooltip, Center, Spinner, Text, Box } from "@chakra-ui/react";

// const MapChart = ({ countryData, zoom, setIsLoading }) => {
//   const [tooltipContent, setTooltipContent] = useState("");
//   const [highlightedState, setHighlightedState] = useState(null);
//   const [isLoading1, setIsLoading1] = useState(true);
//   const [geojsonAllStateURL, setGeojsonAllStateURL] = useState(null);

//   const countryMap = useMemo(() => {
//     const mapByCountryName = new Map();
//     const mapByParentName = new Map();

//     countryData.forEach(({ country_name, value, parent_name }) => {
//       if (country_name) mapByCountryName.set(country_name, value);
//       if (parent_name) mapByParentName.set(parent_name, value);
//     });

//     return { mapByCountryName, mapByParentName };
//   }, [countryData]);

//   useEffect(() => {
//     const fetchGeojsonData = async () => {
//       try {
//         setIsLoading(true);
//         setIsLoading1(true);
//         const response = await fetch(
//           "https://esg-production.s3.us-west-1.amazonaws.com/geojson/geo.geojson"
//         );
//         if (response.ok) {
//           const data = await response.json();
//           setGeojsonAllStateURL(data);
//           setTimeout(() => {
//             setIsLoading(false);
//           }, 3000);
//           setIsLoading1(false);
//         } else {
//           console.error("Failed to load GeoJSON data");
//         }
//       } catch (error) {
//         console.error("Error fetching GeoJSON data:", error);
//       }
//     };

//     fetchGeojsonData();
//   }, []);

//   const handleMouseEnter = (geo, event) => {
//     setTooltipContent(geo.properties.admin);
//     setHighlightedState(geo.properties.name);
//   };

//   const handleMouseLeave = () => {
//     setTooltipContent("");
//     setHighlightedState(null);
//   };

//   // if (isLoading1) {
//   //   return (
//   //     <Center height="100vh" bg="rgba(0, 0, 0, 0.5)" flexDirection="column">
//   //       <Box position="relative">
//   //         <Spinner size="xl" thickness="4px" color="white" />
//   //         <Text color="white" mt={4} fontSize="lg">
//   //           Almost there loading the map now.
//   //         </Text>
//   //       </Box>
//   //     </Center>
//   //   );
//   // }

//   return (
//     <div style={{ width: "100%", height: "auto", position: "relative" }}>
//       <ComposableMap
//         projection="geoMercator"
//         projectionConfig={{
//           scale: 130 * zoom,
//           center: [10, 10],
//         }}
//         style={{
//           width: "100%",
//           margin: "auto",
//           height: "100%",
//         }}
//       >
//         {geojsonAllStateURL && (
//           <Geographies geography={geojsonAllStateURL}>
//             {({ geographies }) =>
//               geographies
//                 .filter((geo) => geo.properties.admin !== "Antarctica")
//                 .map((geo) => {
//                   const countryValue = countryMap.mapByCountryName.get(
//                     geo.properties.name
//                   );
//                   const parentValue = countryMap.mapByParentName.get(
//                     geo.properties.parentName
//                   );
// console.log("object",countryMap.mapByParentName.get(
//   geo.properties.parentName
// ))
//                   const isHighlighted =
//                     countryValue !== undefined || parentValue !== undefined;

//                   return (
//                     <Tooltip
//                       key={geo.rsmKey}
//                       label={
//                         <>
//                           <div>
//                             {geo.properties.admin}
//                             {/* {isHighlighted &&
//                               `: ${parentValue ?? countryValue}`} */}
//                           </div>
//                           <div
//                             style={{
//                               display: "flex",
//                               justifyContent: "flex-start",
//                               alignItems: "baseline",
//                             }}
//                           >
//                             {" "}
//                             {geo.properties.name}:
//                             {isHighlighted ? (
//                               <span style={{ color: "#fff" }}>
//                                 {`${formatNumberWithIndianCommas(countryValue) ?? "No data available"}`}
//                               </span>
//                             ) : (
//                               <span
//                                 style={{
//                                   color: "#fff",
//                                   fontSize: "0.8rem",
//                                   paddingLeft: "10px",
//                                 }}
//                               >
//                                 No data available
//                               </span>
//                             )}
//                           </div>
//                         </>
//                       }
//                     >
//                       <Geography
//                         key={geo.rsmKey}
//                         geography={geo}
//                         fill={
//                           isHighlighted ||
//                           highlightedState === geo.properties.name
//                             ? "#00a0da"
//                             : "#ffffff"
//                         }
//                         stroke="#f7f7f7"
//                         strokeWidth={0.5}
//                         // onMouseEnter={(event) => handleMouseEnter(geo, event)}
//                         // onMouseLeave={handleMouseLeave}
//                       />
//                     </Tooltip>
//                   );
//                 })
//             }
//           </Geographies>
//         )}
//       </ComposableMap>
//     </div>
//   );
// };

// export default React.memo(MapChart);




// function formatNumberWithIndianCommas(number) {
//   if(number===undefined) return
//   const numberParts = number?.toString()?.split('.');
//   let integerPart = numberParts[0];
//   const decimalPart = numberParts[1] ? `.${numberParts[1]}` : '';
  
//   const lastThreeDigits = integerPart.slice(-3);
//   const otherDigits = integerPart.slice(0, -3);
  
//   const formattedInteger = otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + (otherDigits ? ',' : '') + lastThreeDigits;
  
//   return formattedInteger + decimalPart;
// }

import React, { useEffect, useState, useMemo } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { Box, Text, Tooltip } from "@chakra-ui/react";
import { formatNumber } from "./NumberFormat";
import { getMetricdatatypeSymbol } from "./GeographicImpact";

const MapChart = ({ countryData, zoom, setIsLoading }) => {
  const [tooltipContent, setTooltipContent] = useState("");
  const [highlightedState, setHighlightedState] = useState(null);
  const [isLoading1, setIsLoading1] = useState(true);
  const [geojsonAllStateURL, setGeojsonAllStateURL] = useState(null);

  // const countryMap = useMemo(() => {
  //   const mapByCountryName = new Map();
  //   const mapByParentName = new Map();

  //   countryData && countryData.forEach(({ country_name, value, parent_name }) => {
  //     if (country_name) mapByCountryName.set(country_name, value);
  //     if (parent_name) mapByParentName.set(parent_name, value);
  //   });

  //   return { mapByCountryName, mapByParentName };
  // }, [countryData]);

  const countryMap = useMemo(() => {
    const mapByCountryName = new Map();
    const mapByParentName = new Map();
  
    countryData && countryData.forEach(({ country_name, value, parent_name, metric__datatype }) => {
      if (country_name) mapByCountryName.set(country_name, { value, metric__datatype });
      if (parent_name) mapByParentName.set(parent_name, { value, metric__datatype });
    });
  
    return { mapByCountryName, mapByParentName };
  }, [countryData]);
  

  useEffect(() => {
    const fetchGeojsonData = async () => {
      try {
        setIsLoading(true);
        setIsLoading1(true);
        const response = await fetch(
          "https://esg-production.s3.us-west-1.amazonaws.com/geojson/geo.geojson"
        );
        if (response.ok) {
          const data = await response.json();
          setGeojsonAllStateURL(data);
          setTimeout(() => {
            setIsLoading(false);
          }, 3000);
          setIsLoading1(false);
        } else {
          console.error("Failed to load GeoJSON data");
        }
      } catch (error) {
        console.error("Error fetching GeoJSON data:", error);
      }
    };

    fetchGeojsonData();
  }, []);

  const handleMouseEnter = (geo) => {
    setTooltipContent(geo.properties.admin);
    setHighlightedState(geo.properties.name);
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
    setHighlightedState(null);
  };

  return (
    <div style={{ width: "100%", height: "auto", position: "relative" }}>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{
          scale: 130 * zoom,
          center: [10, 10],
        }}
        style={{
          width: "100%",
          margin: "auto",
          height: "100%",
        }}
      >
        {geojsonAllStateURL && (
          <Geographies geography={geojsonAllStateURL}>
            {({ geographies }) =>
              geographies
                .filter((geo) => geo.properties.admin !== "Antarctica")
                .map((geo) => {
                  const countryDatamapvalue = countryMap.mapByCountryName.get(geo.properties.name);
                  const countryValue = countryDatamapvalue?.value;
                  const Mtericdatatype = countryDatamapvalue?.metric__datatype;

                  console.log("countrydata",countryDatamapvalue)
                  // const Mtericdatatype = highlightedCountries.find(
                  //   (country) => country.code === countryCode
                  // )?.metric_type;
                  const isHighlightedState =
                    countryValue !== undefined &&
                    countryData.some(
                      ({ parent_name }) =>
                        parent_name === geo.properties.admin
                    );
// console.log("pused to beta gain")
                  return (
                    <Tooltip
                      key={geo.rsmKey}
                      label={
                        <>
                          <Box>
  <Text sx={{ margin: 0 }}>
    {geo.properties.admin}:{geo.properties.name}
  </Text>
  {countryValue != undefined && (
    <Text sx={{ margin: 0 }}>
    {Mtericdatatype? getMetricdatatypeSymbol(Mtericdatatype):""}  {formatNumber(countryValue)}
    </Text>
  )}
</Box>

                        </>
                      }
                    >
                      <Geography
                        key={geo.rsmKey}
                        geography={geo}
                        fill={
                          isHighlightedState ||
                          highlightedState === geo.properties.name
                            ? "#00a0da"
                            : "#ffffff"
                        }
                        stroke="#f7f7f7"
                        strokeWidth={0.5}
                        // onMouseEnter={() => handleMouseEnter(geo)}
                        // onMouseLeave={handleMouseLeave}
                      />
                    </Tooltip>
                  );
                })
            }
          </Geographies>
        )}
      </ComposableMap>
    </div>
  );
};

export default React.memo(MapChart);
