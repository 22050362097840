import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from './executiveleadershipreviews.module.css'
import API from '../../../../../../Services/API'
import {Heading, Select, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Checkbox, 
    Stack,
} from '@chakra-ui/react'
import DeleteElr from './DeleteElr/DeleteElr'
import AddSlideOne from './AddSlideOne/AddSlideOne';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { DownloadIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import PptxGenJS from 'pptxgenjs';

import SlideZero from './SlideZero/SlideZero';
import SlideOne from './SlideOne/SlideOne';
import SlideTwo from './SlideTwo/SlideTwo'

import FiscalYear from './SlideOne/FiscalYear/FiscalYear';
import GeneratePdf from './GeneratePdf/GeneratePdf';

import ViewPdf from './ViewPdf/ViewPdf';


const ExecutiveLeadershipReviews = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState(false)
    const [elrSectionOne, setElrSectionOne] = useState()
    const [elrMetric, setElrMetric] = useState()
    const [preview, setPreview] = useState(null);
    const [viewOneId, setViewOneId] = useState(null);
    const [impactStories, setImpactStories] = useState(null);
    const [elr, setElr] = useState(null);
    const [noData, setNoData] = useState(0);
    const editor = useRef(null);
    const [status, setStatus] = useState(0);
    const [errorMessage, setErrorMessage] = useState(null);
    const [elrFile, setElrFile] = useState(null);
    const [submitMessage, setSubmitMessage] = useState(null);
    const [fiscalYearList, setFiscalYearList] = useState()
    const [fiscalQtrList, setFiscalQtrList] = useState()
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const [selectedFiscalQtr, setSelectedFiscalQtr] = useState(null);
    const [selectedFiscalYearList, setSelectedFiscalYearList] = useState(false)
    
    const [firstFiscalYear, setFirstFiscalYear] = useState(false)
    const [firstFiscalQtr, setFirstFiscalQtr] = useState(false)
    
    const [elrStatus, setElrStatus] = useState(null)
    const [impactStoryStatus, setImpactStoryStatus] = useState(null)
    const [elrReviewStatus, setElrReviewStatus] = useState(null)

    


    const printSectionRef = useRef(null);
    
    useEffect(()=>{
        setIsLoading(true)
        API.get(`/elr-dashboard?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setElrSectionOne(response.data)
            setPreview(response.data && response.data[0].banner)
            setViewOneId(response.data && response.data[0].id)
            setStatus(response.data && response.data[0].approval_status)
            setElrStatus(response.data && response.data[0].approval_status)
            setFirstFiscalYear(response.data && response.data[0].fiscal_year)
            setFirstFiscalQtr(response.data && response.data[0].fiscal_quarter)
            setIsLoading(false)
            setNoData(0)
        }).catch(error=>{
            setIsLoading(false)
            setNoData(1)
        })

        API.get(`/impact-story?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setImpactStories(response.data)
            setImpactStoryStatus(response.data && response.data[0].approval_status)
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })

        API.get(`/executive-level-review?action=${props.pillarId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            console.log(response.data)

            const allApproved = response.data.every(item => item.approval_status === "Approved by Action Office");

            if (allApproved) {
                setElrReviewStatus("Approved by Action Office")
            } else {
                console.log("Not all records are 'Approved by Action Office'");
            }


            setElr(response.data)
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
        API.get(`/fiscal-year-detail`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setFiscalYearList(response.data[0]?.fiscalyear)
            setFiscalQtrList(response.data[1]?.fiscalquarter)
        })

    },[props.pillarId])
    const [elrBanner, setElrBanner] = useState(null);

    function elrBannerUpload(e) {
        const selectedFile = e.target.files[0];
        setElrBanner(selectedFile);
    }

    const addElr = (event) => {
        setIsLoading(true)
        const formData = new FormData()
        if(elrBanner != null){
            formData.append('banner', elrBanner)
        }
        formData.append('label', $("#elr_label").val())
        formData.append('description', $("#elrDescription").val())
        formData.append('action', parseInt(props.pillarId))
        //formData.append('elr', 1)
        
        API.post(`/executive-level-review`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/executive-level-review?action=${props.pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                onClose()
                setElr(response.data)
                setIsLoading(false)
            }).catch(error=>{
                 
                setIsLoading(false)
            })
        }).catch(error=>{
            
            setIsLoading(false)

        })
    }

    const getUpdateElr = (elrView) =>{
        setElr(elrView)
    }

    const getFirstSlideUpdate = (slideOne) =>{
        setElrSectionOne(slideOne)
        setPreview(slideOne && slideOne.banner)
        setViewOneId(slideOne && slideOne.id)
    }

    const saveElrIn = (event) =>{
        setIsLoading(true)
        const formData = new FormData()
        if(elrBanner != null){
            formData.append('banner', elrBanner)
        }
        formData.append('label', $("#elr_label"+event.target.id).val())
        formData.append('description', $("#elrDescription"+event.target.id).val())
        formData.append('action', parseInt(props.pillarId))
        formData.append('id', event.target.id)
        
        API.put(`/executive-level-review`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/executive-level-review?action=${props.pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                onClose()
                setElr(response.data)
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)

        })
    }

    const elrBannerUploadIn = async (e, elrId) => {
        setElrFile(null)
        const selectedFile = e.target.files[0];
        setElrFile(selectedFile);
    };

    const slideOneUpdate = (slideOneUpdate) => {
        setElrSectionOne(slideOneUpdate)
    }

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null }
            {
                errorMessage !== null ? <Box p={'0px 0 10px 10px'}> {errorMessage && errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()} </Box> : null
            }
            {/* {
                errorMessage !== '' ? <Box> Facing technical issue, please contact your administrator </Box> : null
            } */}
            {
                // status === 'Approved by Action Office' ? <Button onClick={generateMulPdf} className='buttonPrimary' position={'absolute'} right={'30px'}> Download all</Button> : null
            }
            {/* {
                (elrStatus && elrStatus === 'Approved by Action Office') || 
                (impactStoryStatus && impactStoryStatus === 'Approved by Action Office') ||
                (elrReviewStatus && elrReviewStatus === 'Approved by Action Office') ? 
                
                <ViewPdf
                    pillarId={props.pillarId}
                    title={props.title && props.title} 
                    firstFiscalYear={firstFiscalYear} 
                    firstFiscalQtr={firstFiscalQtr}
                /> : null
            } */}
            {
                elrStatus && elrStatus === "Approved by Action Office" &&
                impactStoryStatus && impactStoryStatus === "Approved by Action Office" &&
                elrReviewStatus && elrReviewStatus === "Approved by Action Office" && (
                    <ViewPdf
                        pillarId={props.pillarId}
                        title={props.title && props.title} 
                        firstFiscalYear={firstFiscalYear} 
                        firstFiscalQtr={firstFiscalQtr}
                    />
                )
            }

            {/* <ViewPdf
                pillarId={props.pillarId}
                title={props.title && props.title} 
                firstFiscalYear={firstFiscalYear} 
                firstFiscalQtr={firstFiscalQtr}
            /> */}

            {submitMessage !== null ? <Box> {submitMessage} </Box> : null}

            <SimpleGrid columns={[1]} spacing='10px'>
                <Heading className={Styles.slideHeading} visibility={'hidden'}> Executive Leader Reporting 
                    <GeneratePdf status={status} name={''} />
                </Heading>
                <Box id='pdf-content0'>
                    <SlideZero id='pdf-content0' title={props.title && props.title} firstFiscalYear={firstFiscalYear} firstFiscalQtr={firstFiscalQtr} />
                </Box>

                <Box id='pdf-content1'>
                    <Heading className={Styles.slideHeading}> Executive Leader Reporting 
                    {
                        elrStatus && elrStatus === "Approved by Action Office" &&
                        impactStoryStatus && impactStoryStatus === "Approved by Action Office" &&
                        elrReviewStatus && elrReviewStatus === "Approved by Action Office" && (
                            <GeneratePdf status={status} name={'pdf-content1'} />
                        )
                    }
                        
                    </Heading>
                    {
                        props.editable && (elrSectionOne && elrSectionOne.length < 1) ? <AddSlideOne pillarId={props.pillarId} getFirstSlideUpdate={getFirstSlideUpdate} /> : null
                    }
                    {
                        elrSectionOne && elrSectionOne.length < 1 ? 
                        <Box border={'1px solid #e2e8f0'}>
                            <Text p={'20px'}> There is no data added yet </Text>
                        </Box> : null
                    }

                    {
                        elrSectionOne && elrSectionOne.map(slide1 =>
                            <>
                                <SlideOne 
                                    status={status} 
                                    editable={props.editable}
                                    preview={preview}
                                    slideData={slide1}
                                    pillarId={props.pillarId}
                                    heading1={slide1.heading1}
                                    description1={slide1.description1}
                                    heading2={slide1.heading2}
                                    description2={slide1.description2}
                                    heading3={slide1.heading3}
                                    description3={slide1.description3}
                                    heading4={slide1.heading4}
                                    description4={slide1.description4}
                                    slideOneUpdate={slideOneUpdate}
                                    elrMetric={elrMetric && elrMetric}
                                    firstFiscalYear = {firstFiscalYear && firstFiscalYear}
                                    firstFiscalQtr={firstFiscalQtr && firstFiscalQtr}
                                />
                            </>
                        )
                    }
                </Box>
                <Box id='pdf-content2' ref={printSectionRef}>
                    <Heading className={Styles.slideHeading} mt={'15px'}> Impact Stories
                        
                        {
                            elrStatus && elrStatus === "Approved by Action Office" &&
                            impactStoryStatus && impactStoryStatus === "Approved by Action Office" &&
                            elrReviewStatus && elrReviewStatus === "Approved by Action Office" && (
                                <GeneratePdf status={status} name={'pdf-content2'} />
                            )
                        }
                    </Heading>
                    <SlideTwo
                        editable={props.editable}
                        pillarId={props.pillarId}
                    />
                </Box>
                
                <Box>
                    <Heading className={Styles.slideHeading} mt={'15px'} > Executive Level Review 
                        
                        {
                            elrStatus && elrStatus === "Approved by Action Office" &&
                            impactStoryStatus && impactStoryStatus === "Approved by Action Office" &&
                            elrReviewStatus && elrReviewStatus === "Approved by Action Office" && (
                                <GeneratePdf status={status} name={'pdf-content3'} />
                            )
                        }
                    </Heading>
                    <Box border={'1px solid #e2e8f0'} id='pdf-content3' p={'0px 10px 10px 10px'}>
                    {props.editable ? 
                        <Box>
                            <Button className={'defaultButton newElrButton'}  onClick={onOpen}> Create ELR</Button>     
                            <Box className='clearfix'></Box>
                        </Box>
                    : null}
                    <Box className='clearfix'></Box>
                        {
                            elr && elr.length < 1 ? <Text p={'20px'}> There is no data added yet </Text> : null
                        }
                        
                        <SimpleGrid spacing={4} columns={[3]}>
                            {
                                elr && elr.map((elr, i)=>
                                    <Card>
                                        {
                                            props.editable ? <DeleteElr id={elr.id} getUpdateElr={getUpdateElr} pillarId={props.pillarId}/> : null
                                        }
                                        {
                                            props.editable ? 
                                            <>
                                                <CardHeader>
                                                    <FiscalYear
                                                        editable = {props.editable}
                                                        heading={props.slideData && props.slideData.heading1}
                                                        description={props.slideData && props.slideData.description1}
                                                        pillarId={props.pillarId}
                                                        id={elr.id}
                                                        slideOneUpdate={props.slideOneUpdate}
                                                        slideType={'slide3'}
                                                    />
                                                    <FormControl>
                                                        <FormLabel fontWeight={'bold'}>Label</FormLabel>
                                                        <Input type='text' id={'elr_label'+elr.id} mb={'15px'} defaultValue={elr.label}  />
                                                    </FormControl>
                                                    <FormControl>
                                                        <FormLabel fontWeight={'bold'}>Icon</FormLabel>
                                                        <Input type="file" id={'bannerUpload'+elr.id} onChange={(e) => elrBannerUploadIn(e, elr.id)} padding={"4px"}/>
                                                        <Image src={elr.banner} width={'60px'} mt={'15px'}  alt={props.slideData && props.slideData.heading1} />
                                                    </FormControl>
                                                    
                                                    
                                                </CardHeader>
                                                <CardBody>
                                                    <FormLabel fontWeight={'bold'}>Description</FormLabel>
                                                    <JoditEditor
                                                        id={"elrDescription"+elr.id}
                                                        ref={editor}
                                                        tabIndex={1} // tabIndex of textarea
                                                        onChange={newContent => {}}
                                                        value={elr.description}
                                                    />
                                                </CardBody> 
                                                <CardFooter>
                                                    <Button className='buttonPrimary' onClick={saveElrIn} id={elr.id}> Save </Button>
                                                </CardFooter>                                       
                                            </> : 
                                            <>
                                                <CardHeader>
                                                    <Image src={elr.banner} width={'60px'} alt={elr.label} crossOrigin="anonymous" />
                                                    <Heading size='md' className={Styles.boxHeading}> {elr.label}</Heading>
                                                </CardHeader>
                                                <CardBody className='checkbody'>
                                                    {elr.description != null  ? parse(elr.description) : ''}
                                                </CardBody>
                                            </>
                                        }
                                    </Card> 
                                )
                            }
                        </SimpleGrid>
                    </Box>
                    
                    {/* {status !== 'Approve' ? <Button className='buttonPrimary' display={'block'} m={'20px auto'} onClick={submitElr}> Submit for approval </Button>:null} */}
                </Box>
            </SimpleGrid>
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex} maxW={'580px'}>
                    <ModalHeader>Add new ELR</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel>Label</FormLabel>
                            <Input type='text' id={'elr_label'} mb={'15px'} />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Icon</FormLabel>
                            <Input type="file" onChange={elrBannerUpload} padding={"4px"}/>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Description</FormLabel>
                            <JoditEditor
                                id={"elrDescription"}
                                ref={editor}
                                tabIndex={1} // tabIndex of textarea
                                onChange={newContent => {}}
                            />
                        </FormControl>
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter}>
                        <Button colorScheme='blue' mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button variant='ghost' onClick={addElr}>Add</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ExecutiveLeadershipReviews

