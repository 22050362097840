import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from '../preview.module.css'
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import API from '../../../../../../Services/API';

const SectionTwo = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading] = useState(false)
    const [heading, setHeading] = useState(props.heading)
    const [description, setDescription] = useState(props.description)
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    

    const saveAction = (event) =>{
        setIsLoading(true)

        const formData = new FormData()
        if(file != null){
            formData.append('banner'+props.index, file)
        }
        formData.append('heading'+props.index, heading)
        formData.append('description'+props.index, description)
        formData.append('id', parseInt(event.target.id))
        formData.append('action', parseInt(props.pillarId))
        API.put(`/impact-story`, formData, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/impact-story?action=${props.pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                //props.getSecondSlideUpdate(response.data)
                setSuccessMessage('Updated Successfully')
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
                setErrorMessage(error.response.data.msg)
                props.getError(error.response.data.msg)
            })
        }).catch(error=>{
            setErrorMessage(error.response.data.msg)
        })
    }

    const changeHeading = (event) =>{
        setHeading(event.target.value)
    }
    
    const changeDescription = (event) =>{
        setDescription(event.target.value)
    }

    function sectionImageUpload(e) {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
        // Create a file reader to read the file and generate a preview URL
        const reader = new FileReader();
        reader.onloadend = () => {
            setPreview(reader.result);
        };
        if (selectedFile) {
            reader.readAsDataURL(selectedFile);
        } else {
            setPreview(null);
        }
    }


    return (
        <>
            {
                props.editable ? 
                <Box p={'5px'}>
                    <FormControl border={'1px solid #cccccc'} p={'10px'} borderRadius={'10px'} mb={'15px'}>
                        {
                            errorMessage !== null ? <Box color={'red'}> {errorMessage && errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()} </Box> : null
                        }
                        {
                            successMessage !== null ?  <Box color={'#00a0da'}> {errorMessage && errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()} </Box> : null
                        }
                        <FormLabel fontWeight={'600'} mb={'5px'}> Section {props.index} </FormLabel>
                        <FormLabel fontWeight={'600'} mb={'5px'}> Heading  </FormLabel>
                        <Input defaultValue={props.heading} placeholder={'Please provide Heading '+props.index} mb={'5px'} onChange={changeHeading} />
                        <FormLabel fontWeight={'600'} mb={'5px'}> Upload Icon </FormLabel>
                        <Input type="file" onChange={sectionImageUpload} padding={"4px"} mb={'5px'} />
                        <FormLabel fontWeight={'600'} mb={'5px'}> Description </FormLabel>
                        <Textarea placeholder={'Please provide description '+props.index} defaultValue={props.description} onChange={changeDescription}  />
                        <Button className='buttonPrimary' onClick={saveAction} mt={'10px'} float={'right'} id={props.id}> Save </Button>
                        <Box className='clearfix'></Box>
                    </FormControl>
                </Box>
                : 
                <Box>
                    <Flex>
                        {/* <Avatar src={props.banner} imgProps={{ crossOrigin: "anonymous" }} /> */}
                        <Box className={Styles.avatar}>
                            {props.banner ? <Image src={props.banner} crossOrigin="anonymous" alt="Description" /> : null}
                        </Box>
                        <Box ml='3'>
                            <Text fontWeight='bold' mb={'5px'}>
                                {props.heading}
                            </Text>
                            <Text fontSize='sm'>{props.description}</Text>
                        </Box>
                    </Flex>
                </Box>
            }
        </>
    )
}

export default SectionTwo