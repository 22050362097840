import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Styles from './impactmetrics.module.css'
import API from '../../../../../Services/API';
import {Heading, SimpleGrid, Box, Flex, Switch, Card, CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import AreaChartComponent from '../../../../../Components/Modules/Charts/AreaChartComponent';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import TabLoader from '../../../../../Components/Widgets/CommonWidgets/TabLoader/TabLoader';
import exImage from '../../../../../../assets/images/ex1.png'
import AddImpactMetric from './AddImpactMetric/AddImpactMetric';
import DeleteMetric from './DeleteMetric/DeleteMetric'

const ImpactMetrics = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const [isLoading, setIsLoading]  = useState(false)
  const [impactMetrics, setImpactMetrics] = useState()
  const [pillars, setPillars] = useState()
  const [message, setMessage] = useState()
  

  useEffect(() => {
    setIsLoading(true)
    API.get(`/metric-dashboard?query=impact`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response1=>{
      setImpactMetrics(response1.data)
      setIsLoading(false)
    }).catch(error=>{
      setIsLoading(false)
    })
    
    API.get(`/sio-pillar`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    })
    .then(response=>{
      const sortedPillars = response.data.sort((a, b) => a.name.localeCompare(b.name));
      setPillars(sortedPillars)
    }).catch(error=>{
      setIsLoading(false)
    })

    props.metricType(`impact`)
    props.emptyMetric()
    if(props.selectedOption?.name && props.selectedOption.name ==='year'){
      API.get(`/metric-filter?query=impact&fiscal_year=${props.selectedOption?.value && props.selectedOption.value}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response1=>{
        setImpactMetrics(response1.data)
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
      })
    }else if(props.selectedOption?.name && props.selectedOption.name ==='qtr'){
      API.get(`/metric-filter?query=impact&fiscal_qtr=${props.selectedOption?.value && props.selectedOption.value}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response1=>{
        setImpactMetrics(response1.data)
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
      })
    }else{
      API.get(`/metric-dashboard?query=impact`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response1=>{
        setImpactMetrics(response1.data)
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
      })
    }

  },[])
  
  const getUpdate = (impactMetric) => {
    setImpactMetrics(impactMetric)
    setMessage(true)
  }

  return (
    <>
      {isLoading ? <TabLoader /> : null}
      {props.isLoading && props.isLoading ? <TabLoader /> : null}
      {message ? <Box> Created Impact Metric Successfully </Box> : null}
      <Box maxW={'940px'}>
        {
          pillars && pillars.map((list, index) =>
            <>
              <Heading className={Styles.newTitle}> 
                {list.name}
                {
                  props.edit ? <AddImpactMetric pillarId={list.id} getUpdate={getUpdate} /> : null
                }
              </Heading>
              <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'} mb={'20px'}>
                {
                  impactMetrics && impactMetrics.map((item, key) =>
                    list.id === item.pillar_id ?
                      <Box className={Styles.commonElementStyle} backgroundColor={item.color_picker}>
                        {
                          props.edit ? <DeleteMetric id={item.id} getUpdate={getUpdate} /> : null
                        }
                        
                        <Link to={`impactmetrics/${item.id}/${item.name}`}>
                          <Card maxW='100%' backgroundColor={'transparent'} p={'0px'} className={Styles.customCard}>
                            <CardHeader p={'0px'}>
                              <Flex spacing='4'>
                                <Flex flex='1' gap='2' alignItems='center' flexWrap='wrap'>
                                  <Box display={'flex'}>
                                    {
                                      item.image ? <Image src={item.image} w={'64px'} height={'64px'} alt={item.name}/> : null 
                                    }
                                    {/* <Image src={item.image} w={'64px'} height={'64px'} alt={item.name} /> */}
                                    <Heading className={Styles.customHeadingNumber}  ml={'15px'}>{item.key_fact !== null ? item.key_fact : 0}</Heading>
                                    <Box className='clearfix'> </Box>
                                  </Box>
                                  <Text className={Styles.customPara} width={'100%'}>{item.name}</Text>
                                </Flex>
                              </Flex>
                            </CardHeader>
                          </Card>
                        </Link>
                      </Box>
                    : null
                  )
                }
              </SimpleGrid>
            </>          
          )
        }
      </Box>
      
    </>
  )
}

export default ImpactMetrics




