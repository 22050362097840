import React, { useEffect, useState } from 'react';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { IconButton, Box } from '@chakra-ui/react';
import {Heading, Select, SimpleGrid, Grid, GridItem, Tooltip, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import { ViewIcon, CloseIcon } from '@chakra-ui/icons';
import Styles from '../../../MetricData/metricdata.module.css';
import API from '../../../../../Services/API';
import Preview from './Preview';


const View = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const user =  JSON.parse(window.localStorage.getItem('user'))

    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const [edit, setEdit] = useState(false)

    const togglePane = () => {
        setIsPaneOpen(prevState => !prevState);
    };

    const enableEdit = (event) =>{
        setEdit(event.target.checked)
    }

    return (
        <>
            <Box className={'vieElr'}>
                <Tooltip id={props.id} label="View" aria-label="View"> 
                    <IconButton
                        variant='outline'
                        colorScheme='teal'
                        aria-label='View details'
                        fontSize='14px'
                        icon={<ViewIcon id={props.id} />}
                        className={`${Styles.approve} ${Styles.hoverEffectApprove}`}
                        id={props.id}
                        onClick={togglePane}
                        ml={'5px'}
                    />
                </Tooltip>
                
                <SlidingPane
                    closeIcon={<span className='closeTooltip'> <CloseIcon /> </span>} // Customize close icon if needed
                    isOpen={isPaneOpen}
                    onRequestClose={togglePane}
                    from="right" // Pane direction
                    // Optional: Add custom styles or classes here
                    paneClassName={Styles.paneClass} // Optional: Add a custom class for additional styling
                    title={props.title}
                >
                    <Box>
                        <Heading className={Styles.slideHeading}>  
                            <FormControl display='flex' alignItems='center'>
                                <FormLabel htmlFor='email-alerts' mb='20px' fontSize={'25px'}>
                                    Executive Leader Reporting
                                </FormLabel>
                                {
                                    user && user.action_office_user? (
                                        <Switch id='email-alerts' onChange={enableEdit} />
                                    ) : null
                                }
                            </FormControl>
                            {/* <GeneratePdf status={status} name={'pdf-content1'} /> */}
                        </Heading>
                        <Preview
                            id={props.id}
                            pillar={props.pillar}
                            title={props.label}
                            edit={edit && edit} 
                        />
                    </Box>
                </SlidingPane>
            </Box>            
        </>
    );
};

export default View;