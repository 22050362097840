import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import Styles from '../mytasks.module.css'
import { EditContext } from "../../../Context/BreadcrumbsContext";
import { Box, Button, Card, CardBody } from '@chakra-ui/react'
import Elr from '../Elr/Elr';
import ExecutiveLeaderReporting from './ExecutiveLeaderReporting/ExecutiveLeaderReporting';
import ImpactStories from './ImpactStories/ImpactStories';
import ExecutiveLevelReview from './ExecutiveLevelReview/ExecutiveLevelReview';

const ElrPending = () => {
    const [editable, setEditable] = useState();
    const geteditStatus = (isEdit) =>{
      setEditable(isEdit)
    }
    const [activeTab, setActiveTab] = useState('executiveleaderreporting');
    const { edit, setEdit } = useContext(EditContext);
    const renderTabContent = () => {
        switch (activeTab) {
            case 'executiveleaderreporting': return <ExecutiveLeaderReporting />;
            case 'impactstories': return <ImpactStories />;
            case 'executivelevelreview': return <ExecutiveLevelReview />;
            default : return null;
        }
    };

    return (
        <Box>
            <Box>
                <Button onClick={() => setActiveTab('executiveleaderreporting')} className={activeTab === 'executiveleaderreporting' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Executive Leader Reporting</Button>
                <Button onClick={() => setActiveTab('impactstories')} className={activeTab === 'impactstories' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Impact Stories</Button>
                <Button onClick={() => setActiveTab('executivelevelreview')} className={activeTab === 'executivelevelreview' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Executive Level Review</Button>
            </Box>
            <Box className={Styles.tabContent}>
                {renderTabContent()}
            </Box>
        </Box>   
    )
}

export default ElrPending