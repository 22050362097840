import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../executiveleadershipreviews.module.css'
import API from '../../../../../../../Services/API'
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Checkbox, 
    Stack,
} from '@chakra-ui/react'
import { DeleteIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
const DeleteElr = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState()
    
    async function deleteElr () {
        setIsLoading(true)
        const dataToDelete = {
            "id": props.id
        };
        const headers = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        };

        try {
            const response = await API.delete(`/executive-level-review`, {
                headers: headers,
                data: dataToDelete // Pass the data to delete
            });
            API.get(`/executive-level-review?action=${props.pillarId}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                response.getUpdateElr(response.data)
                setIsLoading(false)
            }).catch(error=>{
                 
                setIsLoading(false)
            })
        } catch (error) {
            console.error("Error:", error.message);
            setIsLoading(false)
        }
    }
    return (
        <>
            <Button className='newDeleteButtonElr' onClick={onOpen}><DeleteIcon  onClick={onOpen} /></Button>
            <Modal isOpen={isOpen} onClose={onClose}  closeOnOverlayClick={false}>
                <ModalOverlay className={Styles.overLay} />
                <ModalContent className={Styles.contentZindex} maxW={'580px'}>
                    <ModalHeader>Delete ELR</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to delete it? This action cannot be reversed.
                    </ModalBody>
                    <ModalFooter className={Styles.modelFooter} backgroundColor={'transparent'}>
                        <Button  mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='blue' onClick={deleteElr}>Confirm</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default DeleteElr