import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from './styles.module.css'
import API from '../../../../Services/API';
import {Heading, SimpleGrid, Box, Button} from '@chakra-ui/react'
import AreaChartComponent from '../../../Modules/Charts/AreaChartComponent';
import LoaderSpinner from '../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import TabLoader from '../../../Widgets/CommonWidgets/TabLoader/TabLoader';
import { InfoOutlineIcon } from '@chakra-ui/icons'
import parse from 'html-react-parser';
import NumberFormat from '../../../Modules/NumberFormat/NumberFormat';
import DeleteMetric from '../DeleteMetric/DeleteMetric';
import PieChartComponent from '../../../Modules/Charts/PieChartComponent';

const OPSMetrics = (props, { metric }) => {
  const token = window.localStorage.getItem("accessToken")
  const {id} = useParams();
  const [isLoading, setIsLoading]  = useState(false)
  const [pillarActions, setPillarActions] = useState()
  const [opsMetrics, setOpsMetrics] = useState()
  const [filterStatus, setFilterStatus] = useState()
  const [pillarId, setPillarId] = useState()
  const [newLoaderLoading, setNewLoaderLoading]  = useState(null)
  const [deleteMessage, setDeleteMessage]  = useState(false)

  
  useEffect(() => {
    setIsLoading(true)
    props.metricType(`operational`)
    props.emptyMetric()
    API.get(`/pillar?module_id=${id}`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then((response) => {
      setPillarId(response.data.id)
      if(props.selectedOption?.name && props.selectedOption.name ==='year'){
        API.get(`/metric-filter?pillar_id=${response.data.id}&query=operational&fiscal_year=${props.selectedOption?.value && props.selectedOption.value}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response1=>{
          setOpsMetrics(response1.data)
          setIsLoading(false)
        }).catch(error=>{
          setIsLoading(false)
        })
      }else if(props.selectedOption?.name && props.selectedOption.name ==='qtr'){
        API.get(`/metric-filter?pillar_id=${response.data.id}&query=operational&fiscal_qtr=${props.selectedOption?.value && props.selectedOption.value}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response1=>{
          setOpsMetrics(response1.data)
          setIsLoading(false)
        }).catch(error=>{
          setIsLoading(false)
        })
      }else{
        API.get(`/metric?pillar_id=${response.data.id}&query=operational`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then(response1=>{
          setOpsMetrics(response1.data)
          setIsLoading(false)
        }).catch(error=>{
          setIsLoading(false)
        })
      }
    })
  },[props.pillarId])

  const handleFlip = (event) => {
    $(event.target).closest('.parentBox').addClass('flipped')
  };
  const handleFlipBack = (event) => {
    $(event.target).closest('.parentBox').removeClass('flipped')
  };
  const currentYear = new Date().getFullYear();

  const getUpatedMetric = (metric) =>{
    setOpsMetrics(metric)
    setDeleteMessage(true)
  }
  const isLoaderloading = (loding) =>{
    setNewLoaderLoading(loding)
  }



  return (
    <>
      {isLoading ? <TabLoader /> : null}
      {props.isLoading && props.isLoading ? <TabLoader /> : null}
      {newLoaderLoading ? <LoaderSpinner /> : null}
      {deleteMessage ? <Box className='colorBlue'> Deleted metric successfully </Box> : null}
      <Box>
        <PieChartComponent 
          metricData={props.filterStatus && props.filterStatus === 1 ? props.metric && props.metric : opsMetrics && opsMetrics} 
        />
      </Box>
      {
        <>
          <Box>
            {
              props.filterStatus && props.filterStatus === 1 ?
              <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'}>
                {
                  props.metric && props.metric.map(metric =>
                    metric.name.includes("CCR GIG by focus area") || metric.name.includes("Breakdown by investment phase") || 
                    metric.name.includes("Breakdown by Funding Round") || metric.name.includes("Global Impact Cash Grants") ? null : 
                    <>
                      <Box maxW='sm' borderWidth='1px' borderRadius='lg' className='parentBox'>
                        <Box className='frontCard'>
                          <InfoOutlineIcon onClick={handleFlip} className='frontFlipIcon' />
                          {
                            props.editable ? 
                              <DeleteMetric 
                                id={metric.id} 
                                pillarId={pillarId} 
                                type={'operational'} 
                                getUpatedMetric={getUpatedMetric}
                                optionName={props.selectedOption.name && props.selectedOption.name}
                                optionValue={props.selectedOption.value && props.selectedOption.value}
                                filterStatus={props.filterStatus && props.filterStatus}
                                isLoaderloading={isLoaderloading}
                              /> 
                            : null 
                          }
                          <Box className='clearfix'></Box>
                          <Link className={Styles.teamLink} to={`/metric/${metric.id}/${encodeURIComponent(metric.name)}`}>
                              <Box p={'10px'}>
                                  <Heading as='h5' size='sm' className={Styles.metricTitle}>
                                      {metric.name}
                                  </Heading>
                                  <Box className='metricCount'>
                                  <>
                                    {
                                      <> 
                                        { 
                                          metric.datatype === 'money' ? "$" : null
                                        } 
                                        {
                                          metric?.chart_value?.chart_top_value?.length > 0 ? 
                                          <>
                                            {
                                              metric.chart_value.chart_top_value.map(item =>
                                                <NumberFormat number={item.value ? item.value : 0} />
                                              )
                                            }
                                          </> : 0
                                        }
                                        {
                                          metric.datatype === 'percent' ? "%" : null
                                        } 
                                      </>
                                    }
                                  </>
                                    
                                  </Box>
                                  <AreaChartComponent chartData={metric?.chart_value?.result} />
                              </Box>
                          </Link>
                        </Box>
                        <Box className='backCard'>
                          <InfoOutlineIcon onClick={handleFlipBack} className='frontFlipIcon' />
                          <Box className='clearfix'></Box>
                          {
                            metric.description === '<p><br></p>' ? 
                              <Box className='noDesc'> No description </Box>
                            : 
                            metric.description === null ? 'No description' : parse(metric.description)
                          }
                        </Box>
                      </Box>
                    </>
                  )
                }
              </SimpleGrid> : 
              <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'}>
                {
                  opsMetrics && opsMetrics.map(metric =>
                    metric.name.includes("CCR GIG by focus area") || metric.name.includes("Breakdown by investment phase") || 
                    metric.name.includes("Breakdown by Funding Round") || metric.name.includes("Global Impact Cash Grants") ? null : 
                    <>
                      <Box maxW='sm' borderWidth='1px' borderRadius='lg' className='parentBox'>
                        <Box className='frontCard'>
                          <InfoOutlineIcon onClick={handleFlip} className='frontFlipIcon' />
                          {props.editable ? <DeleteMetric id={metric.id} pillarId={pillarId} type={'operational'} getUpatedMetric={getUpatedMetric} isLoaderloading={isLoaderloading} /> : null }
                          <Box className='clearfix'></Box>
                          <Link className={Styles.teamLink} to={`/metric/${metric.id}/${encodeURIComponent(metric.name)}`}>
                              <Box p={'10px'}>
                                  <Heading as='h5' size='sm' className={Styles.metricTitle}>
                                      {metric.name}
                                  </Heading>
                                  <Box className='metricCount'>
                                  <>
                                    {
                                      <> 
                                        { 
                                          metric.datatype === 'money' ? "$" : null
                                        } 
                                        {
                                          metric?.chart_value?.chart_top_value?.length > 0 ? 
                                          <>
                                            {
                                              metric.chart_value.chart_top_value.map(item =>
                                                <NumberFormat number={item.value ? item.value : 0} />
                                              )
                                            }
                                          </> : 0
                                        }
                                        {
                                          metric.datatype === 'percent' ? "%" : null
                                        } 
                                      </>
                                    }
                                  </>
                              </Box>
                                  <AreaChartComponent chartData={metric?.chart_value?.result} />
                              </Box>
                          </Link>
                        </Box>
                        <Box className='backCard'>
                          <InfoOutlineIcon onClick={handleFlipBack} className='frontFlipIcon' />
                          <Box className='clearfix'></Box>
                          {
                            metric.description === '<p><br></p>' ? 
                              <Box className='noDesc'> No description </Box>
                            : 
                            metric.description === null ? 'No description' : parse(metric.description)
                          }
                        </Box>
                      </Box>
                    </>
                  )
                }
              </SimpleGrid>
            }
            {
              props.filterStatus && props.filterStatus === 1 ? 
              <>
                {props.metric.length === 0 ? <Box className='successInfoNew'> Data not available now. Please check back later. </Box> : null } 
              </> : 
              <>
                {opsMetrics?.length === 0 ? <Box className='successInfoNew'> Data not available now. Please check back later. </Box> : null } 
              </>
            }
          </Box>
        </>
      }
    </>
  )
}

export default OPSMetrics
