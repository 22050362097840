import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom'
import Styles from '../viewmetric.module.css'
import API from '../../../../../Services/API';
import {Card, CardHeader, CardBody, Heading, Tabs, TabList, TabPanels, Tab, TabPanel, Text, Box, Input, Textarea, Button, Image} from '@chakra-ui/react'
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer, useDisclosure} from '@chakra-ui/react'
import CreateMetricData from './CreateMetricData/CreateMetricData';
import { EditIcon, AddIcon, DeleteIcon } from '@chakra-ui/icons'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import LoaderSpinner from '../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import EditMetricData from './EditMetricData/EditMetricData';
import DataFilter from './DataFilter/DataFilter';
import DeleteMetric from './DeleteMetric/DeleteMetric';
import NumberFormat from '../../../../Modules/NumberFormat/NumberFormat';

const MetricData = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const permissionType = window.localStorage.getItem("permissionType")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [isLoading, setIsLoading] = useState()
    const {metricId} = useParams();
    const [deleteMessage, setDeleteMessage] = useState(false)
    const [allMetrics, setAllMetrics] = useState()
    const [fiscalYearDetail, setFiscalYearDetail] = useState()
    const [fiscalYearQtrDetail, setFiscalYearQtrDetail] = useState()
    const [countries, setCountries] = useState(false)
    useEffect(()=>{
        var metricData = props.metricData && props.metricData

        API.get(`/metric`, {
          headers: {
              'Authorization': 'Bearer ' + token
          }
        }).then(response1=>{
          setAllMetrics(response1.data)
        }).catch(error=>{
            setIsLoading(false)
        })

        API.get(`/fiscal-year-detail`, {
          headers: {
              'Authorization': 'Bearer ' + token
          }
        }).then(response=>{
          setFiscalYearDetail(response.data[0].fiscalyear)
          setFiscalYearQtrDetail(response.data[1].fiscalquarter)
          setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })

        API.get(`/country-list`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setCountries(response.data)
        }).catch(error=>{
            setIsLoading(false)
        })
    },[])

    const getMetricData = (getUpdatedMetricData) => {
        props.getUpdatedMetricData(getUpdatedMetricData)
    }

    const editAction = () => {

    }

    const getUpdatedPending = (metricTask) =>{
        //setMetricData(metricTask)
    }
    
    const getMessage = (metricTask) =>{
        //setMessage(metricTask)
    }
    
    const updatedMessage = (metricTask) =>{
        //setGetUpMessage(metricTask)
    }

    const deleteUpdatedMessage = (message) =>{
        setDeleteMessage(true)
        setTimeout(() => {
            setDeleteMessage(false)
        }, 10000); // Simulate a 2-second delay
    }

    const sortedMetricData = props.metricData ? [...props.metricData].sort((a, b) => b.id - a.id) : [];
    
    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            {deleteMessage ? <Box className='successInfoNew colorBlue'> Deleted Metric-Data Successfully </Box> : null}
                <Box>
                    <DataFilter
                        fiscalYearDetail={fiscalYearDetail && fiscalYearDetail} 
                        fiscalYearQtrDetail={fiscalYearQtrDetail && fiscalYearQtrDetail}
                        filteredMetricData={props.filteredMetricData}
                    />
                </Box>
            <TableContainer>
                {props.editable ? <CreateMetricData getMetricData={getMetricData} title={props.title} /> : null}
                <Table size='sm'>
                    <Thead>
                        <Tr>
                            <Th>Status</Th>
                            <Th>Strategy</Th>
                            <Th>Metric</Th>
                            <Th>Value</Th>
                            <Th>Fiscal Year</Th>
                            <Th>Fiscal QTR</Th>
                            <Th>Geography</Th>
                            <Th>Approve</Th>
                            {
                                permissionType !== 'viewer' ? <Th>Actions</Th> : null
                            }
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            props.metricData && props.metricData.map((metridata, index) => 
                                <Tr>
                                    <Td textTransform={'capitalize'}>{metridata.metric_status.replace(/-/g, " ")}</Td>
                                    <Td textTransform={'capitalize'}>
                                        <Box width={'150px'} whiteSpace={'pre-wrap'}>
                                            {metridata.strategy_name}
                                        </Box>
                                    </Td>
                                    <Td textTransform={'capitalize'}>
                                        <Box width={'150px'} whiteSpace={'pre-wrap'}>
                                            {metridata.name}
                                        </Box>
                                    </Td>
                                    
                                    <Td>
                                        { 
                                            metridata.datatype === 'money' ? " $ " : null
                                        } 
                                        
                                        <NumberFormat number={metridata.value} />
                                        {
                                            metridata.datatype === 'percent' ? " % " : null
                                        } 
                                    </Td>
                                    <Td textTransform={'capitalize'}>
                                        <Box width={'100px'} whiteSpace={'pre-wrap'}>
                                            {metridata.fiscal_year_name}
                                        </Box>
                                        
                                        
                                    </Td>
                                    <Td textTransform={'capitalize'}>{metridata.fiscal_quarter_name}</Td>
                                    <Td textTransform={'capitalize'}>
                                        <Box width={'100px'} whiteSpace={'pre-wrap'}>
                                            {metridata.geography_name}
                                        </Box>
                                    </Td>
                                    <Td textTransform={'capitalize'}>{metridata.approval_status}</Td>
                                    {
                                        permissionType !== 'viewer' ? 
                                            <Td> 
                                                {/* <Button mr={'10px'} className='editNButton' id={metridata.id} onClick={editAction}>
                                                    <EditIcon id={metridata.id} onClick={editAction} />
                                                </Button> */}
                                                <Box width={'150px'} whiteSpace={'pre-wrap'}>
                                                    <EditMetricData 
                                                        metricName={metridata.metric_name}
                                                        period={metridata.fiscal_year}
                                                        fyq={metridata.fiscal_quarter}
                                                        periodName={metridata.fiscal_year_name}
                                                        fyqName={metridata.fiscal_quarter_name}
                                                        metricData={allMetrics && allMetrics}
                                                        fiscalYearDetail={fiscalYearDetail}
                                                        fiscalYearQtrDetail={fiscalYearQtrDetail}
                                                        value={metridata.value}
                                                        geography={metridata.geography}
                                                        geographyName={metridata.geography_name}
                                                        approvalStatus={metridata.approval_status}
                                                        status = {metridata.metric_status}
                                                        id={metridata.id}
                                                        getUpdatedPending={getUpdatedPending}
                                                        getMessage={updatedMessage}
                                                        getUpdatedMetricData={props.getUpdatedMetricData}
                                                        countries={countries && countries}
                                                    />
                                                    <DeleteMetric 
                                                        id={metridata.id}
                                                        name={metridata.metric_name}
                                                        metricId={metricId}
                                                        getUpdatedMetricData={props.getUpdatedMetricData}
                                                        deleteUpdatedMessage={deleteUpdatedMessage}
                                                    />
                                                </Box>
                                            </Td>
                                        : null
                                    }
                                    
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
}

export default MetricData