import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from './sectionTwo.module.css'
import API from '../../../../../../../../../Services/API';
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import PdfSectionOne from './PdfSectionOne/PdfSectionOne';
import PdfSectionTwo from './PdfSectionTwo/PdfSectionTwo';
import PdfSectionThree from './PdfSectionThree/PdfSectionThree';

import FiscalYear from '../../../SlideOne/FiscalYear/FiscalYear';

const SectionTwo = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const [impactStories, setImpactStories] = useState(null);

  useEffect(()=>{
    API.get(`/impact-story?action=${props.pillarId}`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      setImpactStories(response.data)
    }).catch(error=>{
    })
  },[props.pillarId])

  return (
    <>
      {
        impactStories && impactStories.length > 0 ? (
          impactStories && impactStories.map((story, i) => 
            <>
              <Box className={Styles.content}  maxW="1024px" margin="0 auto"> 
                <Grid
                  templateRows='repeat(2, 1fr)'
                  templateColumns='repeat(3, 1fr)'
                  gap={0}
                >
                  <GridItem rowSpan={2} colSpan={1}>
                    <Box className={Styles.leftGrid}>
                      <PdfSectionOne 
                        id={story.id}
                        label = {story.label}
                        banner={story.banner}
                        sublabel={story.sublabel}
                        editable={props.editable}
                        pillarId={props.pillarId}
                      />
                    </Box>
                    <Box>
                      <FiscalYear
                        heading={props.slideData && props.slideData.heading1}
                        description={props.slideData && props.slideData.description1}
                        pillarId={props.pillarId}
                        id={story.id}
                        slideOneUpdate={props.slideOneUpdate}
                        slideType={'slide2'}
                      />
                    </Box>
                  </GridItem>
                  <GridItem rowSpan={2} colSpan={2} pl={'25px'}>
                    <PdfSectionTwo
                      id={story.id}
                      editable={props.editable}
                      pillarId={props.pillarId}
                      heading={story.heading1}
                      banner={story.banner1}
                      description={story.description1}
                      index={1}
                    />
                    <PdfSectionTwo
                      id={story.id}
                      editable={props.editable}
                      pillarId={props.pillarId}
                      heading={story.heading2}
                      banner={story.banner2}
                      description={story.description2}
                      index={2}
                    />
                    <PdfSectionTwo
                      id={story.id}
                      editable={props.editable}
                      pillarId={props.pillarId}
                      heading={story.heading3}
                      banner={story.banner3}
                      description={story.description3}
                      index={3}
                    />
                  </GridItem>
                </Grid>
              </Box>
              <Box className={Styles.footer+" footernew"} backgroundColor={'#00a0da'} textAlign={'left'} color={'#ffffff'}>
                <SimpleGrid columns={[1, 2, 3]} spacing='20px'>
                  <PdfSectionThree
                    id={story.id}
                    pillarId={props.pillarId}
                    banner={story.footerbanner1}
                    description={story.footer1}
                    index={1}
                  />
                  <PdfSectionThree
                    id={story.id}
                    pillarId={props.pillarId}
                    banner={story.footerbanner2}
                    description={story.footer2}
                    index={2}
                  />
                  <PdfSectionThree
                    id={story.id}
                    pillarId={props.pillarId}
                    banner={story.footerbanner3}
                    description={story.footer3}
                    index={3}
                  />
                </SimpleGrid>
              </Box>
            </>
          )
        ):  <Text p={'20px'}> There is no data added yet </Text>
      }
    </>
  )
}

export default SectionTwo