import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import Style1 from './viewImpact.module.css'
import $ from 'jquery'
import { Link, useParams } from 'react-router-dom'
import Styles from '../impactmetrics.module.css'
import API from '../../../../../../Services/API'
import { Heading, SimpleGrid, Box, Stack, StackDivider, Card, CardHeader, CardBody, CardFooter, Image, Text, Input, Button, Wrap, WrapItem, Avatar, AvatarBadge, AvatarGroup, FormControl, FormLabel, Select } from '@chakra-ui/react'
import { SketchPicker } from 'react-color';
import { EditContext } from "../../../../../../Context/BreadcrumbsContext";
import LoaderSpinner from '../../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const ViewMetric = () => {
  const token = window.localStorage.getItem("accessToken")
  const [isLoading, setIsLoading] = useState(true)
  const [editable, setEditable] = useState();
  const [action, setAction] = useState();
  const { id } = useParams();
  const editor = useRef(null);
  const [file, setFile] = useState(null);
  const [metric, setMetric] = useState();
  const [fiscalYearList, setFiscalYearList] = useState()
  const [fiscalQtrList, setFiscalQtrList] = useState()
  const [aspirations, setAspirations] = useState()
  const [metricData, setMetricData] = useState()
  const [isMetricDataAvail, setisMetricDataAvail] = useState(false)
  const { edit, setEdit } = useContext(EditContext);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState('#ffffff');
  const [countries, setCountries] = useState(false)
  
  const [selectedFiscalYr, setSelectedFiscalYr] = useState(null)

  const [selectedFiscalYearList, setSelectedFiscalYearList] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  const [validationError, setValidationError] = useState({
    name: '',
    value: '',
    metricstatus: '',
    fiscalyear: '',
    fiscalquarter: '',
  });

  const [validateMessage, setValidateMessage] = useState(null)


  function bannerUpload(e) {
    setFile(e.target.files[0]);
  }
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (newColor) => {
    setColor(newColor.hex);
  };
  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
    setIsLoading(true)
    API.get(`/get-metric?metric_id=${id}`)
      .then((response) => {
        setMetric(response.data[0])
        setColor(response.data[0]?.color_picker);
        setIsLoading(false)
      }).catch(error => {
      
      })
    API.get(`/metric-data?metric_id=${id}`)
      .then((response) => {
        setMetricData(response.data[0])

        let charArray = response.data[0]?.fiscal_year_name?.split("")
        const fisclyr = charArray[0] + charArray[1] + charArray[4] + charArray[5];
        
        setSelectedFiscalYr(response.data[0]?.fiscal_year)
        setSelectedFiscalYearList(fisclyr)
        setIsLoading(false)
      }).catch(error => {
        
      })
    API.get(`/metric-data-entries?id=${id}`,)
      .then((response) => {
        setisMetricDataAvail(response.data.status)
        setIsLoading(false)
      }).catch(error => {
        
      })
    }, [id])
    

  const updateMetricData = (method) => {
    setIsLoading(true)
    
    console.log(selectedFiscalYr)
    if(selectedFiscalYr !== null){
      const body = {
        "metric": id,
        "id":metricData?.id,
        "name": $("#impactName").val(),
        "approval_status": "approved",
        "metric_status": "on-track",
        "fiscal_year": parseInt($("#metricData_targetfiscalyear").val()),
        "fiscal_quarter": parseInt($("#metricData_targetfiscalquarter").val()),
        "geography": parseInt($("#metricData_geography").val()),
        
      }
      if (method) {
        API.put(`/metric-data`, body, {
          headers: { 'Authorization': 'Bearer ' + token }
        })
        .then(response => {
          API.get(`/metric-data?metric_id=${id}`)
          .then((response) => {
            setMetricData(response.data[0])
            let charArray = response.data[0]?.fiscal_year_name?.split("")
            const fisclyr = charArray[0] + charArray[1] + charArray[4] + charArray[5];
            setSelectedFiscalYr(response.data[0]?.fiscal_year)
            setSelectedFiscalYearList(fisclyr)
            setIsLoading(false)
          }).catch(error => {
            
          })
        })
        .catch(err => {  
          //alert(err?.response?.data?.msg);
          console.log(err)
        });
        setIsLoading(false);
      }
       else {
        API.post(`/metric-data`, body, {
          headers: { 'Authorization': 'Bearer ' + token }
        })
        .then(response => {
          API.get(`/metric-data?metric_id=${id}`)
          .then((response) => {
            setMetricData(response.data[0])
            let charArray = response.data[0]?.fiscal_year_name?.split("")
            const fisclyr = charArray[0] + charArray[1] + charArray[4] + charArray[5];
            setSelectedFiscalYr(response.data[0]?.fiscal_year)
            setSelectedFiscalYearList(fisclyr)
            setIsLoading(false)
          }).catch(error => {
            
          })
        })
        .catch(err => {
          //alert(err?.response?.data?.msg);
          console.log(err)
        });
        setIsLoading(false);
      }
    }else{
      setValidateMessage("Please select fiscal year")
      setIsLoading(false)
    }
    
  }

  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const updateMetric = () => {
    setIsLoading(true)
    setSuccessMessage(null)

    
    if (isMetricDataAvail) {
      updateMetricData(true)
    } else {
      updateMetricData(false)
    }
    setIsLoading(true)
    if(selectedFiscalYr !== null){
      const formData = new FormData()
      if (file != null) {
        formData.append('image', file)
      }
      formData.append('name', $('#impactName').val())
      formData.append('key_fact', $('#keyfact').val())
      formData.append('color_picker', color)
      formData.append('id', id)

      API.put(`/metric`, formData, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        API.get(`/get-metric?metric_id=${id}`)
          .then((response) => {
            setMetric(response.data[0])
            setColor(response.data[0]?.color_picker);
            setIsLoading(false)
            setSuccessMessage('Changes updated successfully')
          }).catch(error => {
            setIsLoading(false)
          })
      })
    }else{
      setValidateMessage("Please select fiscal year")
      setIsLoading(false)
    }
  }

  useEffect(() => {
    API.get(`/fiscal-year-detail`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response => {
      setFiscalYearList(response.data[0].fiscalyear)
      setFiscalQtrList(response.data[1].fiscalquarter)
    })

    API.get(`/country-list`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response => {
      setCountries(response.data)
    })


  }, [])

  const getFiscalYear = (event, params) => {

    setSelectedFiscalYr(event.target.value)

    // const selectedOption = event.target[event.target.selectedIndex];
    // const dataValue = selectedOption.dataset.value;
    // const getYear = dataValue.match(/\d{4}/)
    // if (getYear) {
    //   setSelectedFiscalYearList("FY" + getYear[0].toString().substring(2))
    // }

  }

  return (
    <>
      {isLoading ? <LoaderSpinner /> : null}
      {
        successMessage !== null ? <Box className='colorBlue'> {successMessage} </Box> : null
      }
      {
        errorMessage !== null ? <Box className='colorRed'> {successMessage} </Box> : null
      }
      {
        validateMessage ? <Box color={'red'}> {validateMessage} </Box> : null
      }
      {
        edit ? 
          <Box>
            <FormControl>
              <FormLabel fontWeight={'bold'}> Key Fact </FormLabel>
              <FormControl>
                <Input defaultValue={metric && metric.key_fact} id='keyfact' />
              </FormControl>
            </FormControl>
          </Box>
        : 
        <>
          <FormLabel fontWeight={'bold'}> Key Fact </FormLabel>
          <Heading mb={'20px'} className={Style1.Heading}>  {metric && metric.key_fact} </Heading>
        </>
      }
      <SimpleGrid columns={[1, 2]} spacing='20px' mt={'10px'} mb={'20px'}>
        <Box>
          <Card>
            <CardBody>
              <Stack divider={<StackDivider />} spacing='4'>
                <Box>
                  <Heading className={Style1.HeadingSub} textTransform='uppercase'>
                    Title
                  </Heading>
                  {
                    edit ? 
                      <Box>
                        <FormControl>
                          <Input defaultValue={metric && metric.name} id='impactName' />
                        </FormControl>
                      </Box> 
                    : 
                    <Text pt='2' fontSize='sm' mb={'0'}>
                      {capitalizeFirstLetter(metric && metric.name)}
                    </Text>
                  }
                </Box>
                {/* <Box>
                  <Heading className={Style1.HeadingSub} textTransform='uppercase'>
                    Key Fact
                  </Heading>
                  {
                    edit ? 
                      <Box>
                        <FormControl>
                          <Input defaultValue={metric && metric.key_fact} id='keyfact' />
                        </FormControl>
                      </Box> 
                    : 
                    <Text pt='2' fontSize='sm' mb={'0'}>
                      {metric && metric.key_fact}
                    </Text>
                  }
                </Box> */}
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Fiscal Year
                  </Heading>
                  {
                    edit ? 
                      <FormControl>
                        <Select id={'metricData_targetfiscalyear'} mb={'15px'} onChange={getFiscalYear} className={validationError.targetfiscalyear ? 'redBorder' : ''}>
                          <option value={""} >{" Select fiscal year"} </option>
                          {
                            fiscalYearList && fiscalYearList.map(item => {
                              return (
                                <option value={item.id} selected={metricData?.fiscal_year_name === item.name} data-value={item.name}> {item.name} </option>
                              )
                            }
                            )
                          }
                        </Select>                
                      </FormControl>
                    : 
                    <Text pt='2' fontSize='sm' mb={'0'}>
                      {metricData?.fiscal_year_name}
                    </Text>
                  }
                  
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Fiscal Quarter
                  </Heading>
                  {
                    edit ? 
                    <FormControl>
                      <Select id={'metricData_targetfiscalquarter'} mb={'15px'}>
                        <option value={''}> Select fiscal quarter </option>
                        {
                          fiscalQtrList && fiscalQtrList.map(item =>
                            selectedFiscalYearList === item.name.substring(0, 4) ?
                              <option value={item.id}  selected={ metricData?.fiscal_quarter_name === item.name} > {item.name} </option> : null
                          )
                        }
                      </Select>
                    </FormControl>
                    :
                    <Text pt='2' fontSize='sm' mb={'0'}>
                      {metricData?.fiscal_quarter_name}
                    </Text>
                  }
                </Box>
                <Box>
                  <Heading size='xs' textTransform='uppercase'>
                    Region
                  </Heading>
                  {
                    edit ? 
                    <FormControl>
                      <Select id={'metricData_geography'} mb={'15px'}>
                        <option value={''}> Select region </option>
                        {
                          countries && countries.map(item =>
                            <option value={item.id}  selected={ metricData?.geography_name === item.name} > {item.name} </option>
                          )
                        }
                      </Select>
                    </FormControl>
                    : 
                    <Text pt='2' fontSize='sm' mb={'0'}>
                      {metricData?.geography_name}
                    </Text>
                  }
                  
                </Box>
              </Stack>
            </CardBody>
          </Card>
          
          
        </Box>
        <Box>
          <Card>
            <CardBody>
            <FormLabel fontWeight={'bold'} mt={'10px'}>Background Colour</FormLabel>
              {
                edit ? 
                  <FormControl>
                    <Box w={'50px'} height={'50px'} backgroundColor={color} boxShadow={'1px 1px 11px #e5e5e5'} mb={'5px'}>  </Box>
                    <Input
                        type="text"
                        value={color}
                        onClick={handleClick}
                        readOnly
                        mb={'15px'}
                    />
                    
                    {displayColorPicker && (
                        <Box style={{ position: 'absolute', zIndex: '2' }}>
                        <Box
                            style={{
                                position: 'fixed',
                                top: '0px',
                                right: '0px',
                                bottom: '0px',
                                left: '0px',
                            }}
                            onClick={handleClose}
                        />
                            <SketchPicker color={color} onChange={handleChange} />
                        </Box>
                    )}
                  </FormControl>
                : 
                <Card>
                  <CardBody>
                    <Box w={'100%'} height={'200px'} backgroundColor={color}>  </Box>
                  </CardBody>
                </Card>
              }
              
              {
                metric && metric.image ? <Image src={metric && metric.image} height={'179px'} alt='Card Image'/> : null 
              }
              
              { edit ? 
                <FormControl>
                  <FormLabel fontWeight={'bold'}>Card Image</FormLabel>
                  <Input type="file" onChange={bannerUpload} padding={"4px"} height={'179px'} />
                </FormControl> : null 
              }
            </CardBody>
          </Card>
        </Box>
        {edit ? <Button className='buttonPrimary' onClick={updateMetric}> Update </Button> : null}
      </SimpleGrid>
      {/* <Text as='h3'> Period  </Text> */}
    </>
  )
}

export default ViewMetric
