import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import Styles from './allmetrics.module.css'
import API from '../../../../../Services/API';
import {Heading, SimpleGrid, Box, } from '@chakra-ui/react'
import AreaChartComponent from '../../../../../Components/Modules/Charts/AreaChartComponent';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner'
import TabLoader from '../../../../../Components/Widgets/CommonWidgets/TabLoader/TabLoader';
import { InfoOutlineIcon } from '@chakra-ui/icons'
import parse from 'html-react-parser';
import NumberFormat from '../../../../../Components/Modules/NumberFormat/NumberFormat';
import DeleteMetric from '../DeleteMetric/DeleteMetric';

const AllMetrics = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const [isLoading, setIsLoading]  = useState(false)
  const [allMetrics, setAllMetrics] = useState()
  const [filterStatus, setFilterStatus] = useState()
  const [pillarId, setPillarId] = useState()
  const [newLoaderLoading, setNewLoaderLoading]  = useState(null)
  const [deleteMessage, setDeleteMessage]  = useState(false)

  useEffect(() => {
    setIsLoading(true)
    API.get(`/all-metric-dashboard`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response1=>{
      setAllMetrics(response1.data)
      setIsLoading(false)
    }).catch(error=>{
      setIsLoading(false)
    })

    setIsLoading(true)
    props.metricType(`allmetric`)
    props.emptyMetric()

    if(props.selectedOption?.name && props.selectedOption.name ==='year'){
      API.get(`/metric-filter?fiscal_year=${props.selectedOption?.value && props.selectedOption.value}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response1=>{
        setAllMetrics(response1.data)
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
      })
    }else if(props.selectedOption?.name && props.selectedOption.name ==='qtr'){
      API.get(`/metric-filter?fiscal_qtr=${props.selectedOption?.value && props.selectedOption.value}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response1=>{
        setAllMetrics(response1.data)
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
      })
    }else{
      API.get(`/all-metric-dashboard`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response1=>{
        setAllMetrics(response1.data)
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
      })
    }

  },[])
  const currentYear = new Date().getFullYear();
  const handleFlip = (event) => {
    $(event.target).closest('.parentBox').addClass('flipped')
  };
  const handleFlipBack = (event) => {
    $(event.target).closest('.parentBox').removeClass('flipped')
  };
  const getUpatedMetric = (metric) =>{
    setAllMetrics(metric)
    setDeleteMessage(true)
  }
  const isLoaderloading = (loding) =>{
    setNewLoaderLoading(loding)
  }

  return (
    <>
      {isLoading ? <TabLoader /> : null}
      {props.isLoading && props.isLoading ? <TabLoader /> : null}
      {newLoaderLoading ? <LoaderSpinner /> : null}
      {deleteMessage ? <Box className='colorBlue'> Deleted metric successfully </Box> : null}
      {
        <Box>
          {
            props.filterStatus && props.filterStatus === 1 ?              
            <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'}>
              {
                props.metric && props.metric.map(metric => 
                  <>
                    <Box maxW='sm' borderWidth='1px' borderRadius='lg' className='parentBox'>
                      <Box className='frontCard'>
                        <InfoOutlineIcon onClick={handleFlip} className='frontFlipIcon' />
                        {
                          props.edit ? 
                            <DeleteMetric 
                              id={metric.id} 
                              type={'allmetrics'} 
                              getUpatedMetric={getUpatedMetric}
                              optionName={props.selectedOption.name && props.selectedOption.name}
                              optionValue={props.selectedOption.value && props.selectedOption.value}
                              filterStatus={props.filterStatus && props.filterStatus}
                              isLoaderloading={isLoaderloading}
                            /> 
                          : null 
                        }
                        <Box className='clearfix'></Box>
                        <Link className={Styles.teamLink} to={`/metric/${metric.id}/${encodeURIComponent(metric.name)}`}>
                            <Box p={'10px'}>
                                <Heading as='h5' size='sm' className={Styles.metricTitle}>
                                    {metric.name}
                                </Heading>
                                
                                <Box className='metricCount'>
                                  <>
                                    {
                                      <> 
                                        { 
                                          metric.datatype === 'money' ? "$" : null
                                        }
                                        {
                                          metric?.chart_value?.chart_top_value?.length > 0 ? 
                                          <>
                                            {
                                              metric.chart_value.chart_top_value.map(item =>
                                                <NumberFormat number={item.value ? item.value : 0} />
                                              )
                                            }
                                          </> : 0
                                        }
                                        {
                                          metric.datatype === 'percent' ? "%" : null
                                        } 
                                      </>
                                    }
                                  </>
                                </Box>
                                <AreaChartComponent chartData={metric?.chart_value?.result} />
                            </Box>
                        </Link>
                      </Box>
                      <Box className='backCard'>
                        <InfoOutlineIcon onClick={handleFlipBack} className='frontFlipIcon' />
                        <Box className='clearfix'></Box>
                        {
                          metric.description === '<p><br></p>' ? 
                            <Box className='noDesc'> No description </Box>
                          : 
                          metric.description === null ? 'No description' : parse(metric.description)
                        }
                      </Box>
                    </Box>
                  </>
                )
              }
            </SimpleGrid> : 
            <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'}>
              {
                allMetrics && allMetrics.map(metric => 
                  <>
                    <Box maxW='sm' borderWidth='1px' borderRadius='lg' className='parentBox'>
                      <Box className='frontCard'>
                        <InfoOutlineIcon onClick={handleFlip} className='frontFlipIcon' />
                        {props.edit ? <DeleteMetric id={metric.id} type={'allmetrics'} getUpatedMetric={getUpatedMetric} isLoaderloading={isLoaderloading} /> : null }
                        <Box className='clearfix'></Box>
                        <Link className={Styles.teamLink} to={`/metric/${metric.id}/${encodeURIComponent(metric.name)}`}>
                            <Box p={'10px'}>
                                <Heading as='h5' size='sm' className={Styles.metricTitle}>
                                    {metric.name}
                                </Heading>
                                <Box className='metricCount'>
                                  <>
                                    {
                                      <> 
                                        { 
                                          metric.datatype === 'money' ? "$" : null
                                        }
                                        {
                                          metric?.chart_value?.chart_top_value?.length > 0 ? 
                                          <>
                                            {
                                              metric.chart_value.chart_top_value.map(item =>
                                                <NumberFormat number={item.value ? item.value : 0} />
                                              )
                                            }
                                          </> : 0
                                        }
                                        {
                                          metric.datatype === 'percent' ? "%" : null
                                        } 
                                      </>
                                    }
                                  </>
                                </Box>
                                <AreaChartComponent chartData={metric?.chart_value?.result} />
                            </Box>
                        </Link>
                      </Box>
                      <Box className='backCard'>
                        <InfoOutlineIcon onClick={handleFlipBack} className='frontFlipIcon' />
                        <Box className='clearfix'></Box>
                        {
                          metric.description === '<p><br></p>' ? 
                            <Box className='noDesc'> No description </Box>
                          : 
                          metric.description === null ? 'No description' : parse(metric.description)
                        }
                      </Box>
                    </Box>
                  </>
                )
              }
            </SimpleGrid>
          }
          {
            props.filterStatus && props.filterStatus === 1 ? 
            <>
              {props.metric.length === 0 ? <Box className='successInfoNew'> There are no metric(s) assigned to this action yet. </Box> : null } 
            </> : 
            <>
              {allMetrics?.length === 0 ? <Box className='successInfoNew'> There are no metric(s) assigned to this action yet. </Box> : null } 
            </>
          }
        </Box>
      }
    </>
  )
}

export default AllMetrics