import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../../../../executiveleadershipreviews.module.css'
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import Select from 'react-select';
import API from '../../../../../../../../../../Services/API';
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import NumberFormat from '../../../../../../../../../Modules/NumberFormat/NumberFormat';

const MetricInfo = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading] = useState(false)
    const [elrMetric, setElrMetric] = useState()


    useEffect(()=>{
        setIsLoading(true)
        API.get(`/elr-sum-metric?action=${props.pillarId}&status=on-track`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setElrMetric(response.data)
            setIsLoading(false)
        }).catch(error=>{
             
            setIsLoading(false)
        })
    },[props.pillarId])

    const statusChange = (event) => {
        
        API.get(`/elr-sum-metric?action=${props.pillarId}&status=${event.value}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setElrMetric(response.data)
        }).catch(error=>{
             
        })
    }

    const options = [
        { value: 'on-track', label: 'On Track' },
        { value: 'delay', label: 'Delay' },
        { value: 'acceleration', label: 'Acceleration ' }
    ];

    return (
        <GridItem colSpan={3}>
            <Box p={'20px'}>
                <Box className={Styles.toggleSection} float={'right'} id={'exclude1'}>
                    <Box>
                        <Text float={'left'} mr={'10px'} mt={'5px'}>
                            Action execution status :  
                        </Text>
                        <Select
                            onChange={statusChange}
                            options={options}
                            className={Styles.floatR}
                        />
                    </Box>
                </Box>
                <Box className='clearfix'></Box>
                <Box className={Styles.rightParent} w={'100%'}>
                    {elrMetric && elrMetric.length === 0 ? <Box> There is no data found related your selection </Box> : null }
                    <SimpleGrid columns={[1, null, 3]} spacing='10px'>
                        {
                            elrMetric && elrMetric.map((metric, index) =>
                                <Box className={Styles.loopBox}>
                                    <Stat className={Styles.stat}>
                                        <StatNumber className={Styles.StatNumber} fontSize={'14px'}>
                                            { 
                                                metric.data_type === 'money' ? " $ " : 
                                                metric.data_type === 'number' ? " # " : null
                                            } 
                                            <NumberFormat number={metric.value} />
                                            {
                                                metric.data_type === 'percent' ? " % " : null
                                            } 
                                        </StatNumber>
                                        <StatLabel className={Styles.StatLabel}>
                                            <Text fontWeight={'bold'} fontSize={'12px'}> {metric.metric_name} </Text>
                                            {metric.description === '<p><br></p>' ? 'No description added' : parse(metric.description)}
                                        </StatLabel>
                                    </Stat>
                                </Box>
                            )
                        }
                    </SimpleGrid>
                </Box>
            </Box>
        </GridItem>
    )
}

export default MetricInfo