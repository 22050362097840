import React, { useEffect, useState } from 'react';
import Styles from './preview.module.css'
import API from '../../../../../Services/API';
import {Heading, Select, Box, SimpleGrid, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import MetricInfo from '../../../../../Components/ProjectModule/Actions/ViewAction/ActionTabs/ExecutiveLeadershipReviews/SlideOne/MetricInfo/MetricInfo';
import SectionOne from './SectionOne/SectionOne';
import SectionTwo from './SectionTwo/SectionTwo';
import SectionThree from './SectionThree/SectionThree';

const Preview = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [selectedData, setSelectedData] = useState(null)
    useEffect(()=>{
        API.get(`/impact-story?id=${props.id}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            console.log(response.data)
            setSelectedData(response?.data[0])
            
        }).catch(error=>{

        })
    },[props.pillar])
    return (
        <>
            <Box className={Styles.content}> 
                <Grid
                    templateRows='repeat(2, 1fr)'
                    templateColumns='repeat(3, 1fr)'
                    gap={0}
                >
                    <GridItem rowSpan={3} colSpan={1}>
                        <Box className={Styles.leftGrid}>
                            <SectionOne 
                                id={selectedData && selectedData.id}
                                label = {selectedData && selectedData.label}
                                banner={selectedData && selectedData.banner}
                                sublabel={selectedData && selectedData.sublabel}
                                editable={props.editable}
                                pillarId={props.pillar}
                            />
                        </Box>
                    </GridItem>
                    <GridItem rowSpan={3} colSpan={2}>
                        <SectionTwo 
                            id={selectedData && selectedData.id}
                            editable={props.editable}
                            pillarId={props.pillar}
                            heading={selectedData && selectedData.heading1}
                            banner={selectedData && selectedData.banner1}
                            description={selectedData && selectedData.description1}
                            index={1}
                        />
                        <SectionTwo 
                            id={selectedData && selectedData.id}
                            editable={props.editable}
                            pillarId={props.pillar}
                            heading={selectedData && selectedData.heading2}
                            banner={selectedData && selectedData.banner2}
                            description={selectedData && selectedData.description2}
                            index={2}
                        />
                        <SectionTwo 
                            id={selectedData && selectedData.id}
                            editable={props.editable}
                            pillarId={props.pillar}
                            heading={selectedData && selectedData.heading3}
                            banner={selectedData && selectedData.banner3}
                            description={selectedData && selectedData.description3}
                            index={3}
                        />
                    </GridItem>
                </Grid>
            </Box>
            <Box className={Styles.footer+" footernew"} backgroundColor={'#00a0da'} textAlign={'left'} color={'#ffffff'}>
                <SimpleGrid columns={[1, 2, 3]} spacing='20px'>
                    <SectionThree
                        id={selectedData && selectedData.id}
                        editable={props.editable}
                        pillarId={props.pillar}
                        banner={selectedData && selectedData.footerbanner1}
                        description={selectedData && selectedData.footer1}
                        index={1}
                    />
                    <SectionThree
                        id={selectedData && selectedData.id}
                        editable={props.editable}
                        pillarId={props.pillar}
                        banner={selectedData && selectedData.footerbanner2}
                        description={selectedData && selectedData.footer2}
                        index={2}
                    />
                    <SectionThree
                        id={selectedData && selectedData.id}
                        editable={props.editable}
                        pillarId={props.pillar}
                        banner={selectedData && selectedData.footerbanner3}
                        description={selectedData && selectedData.footer3}
                        index={3}
                    />
                </SimpleGrid>
            </Box>
        </>
    )
}

export default Preview