import React from 'react'
import SectionZero from './SectionZero/SectionZero'
import SectionOne from './SectionOne/SectionOne'
import SectionTwo from './SectionTwo/SectionTwo'
import SectionThree from './SectionTwree/SectionThree'
import {Box} from '@chakra-ui/react'
const PDFContent = (props) => {
    return (
        <Box>
            <Box id={'pdf-content-0'}>
                <SectionZero title={props.title && props.title} firstFiscalYear={props.firstFiscalYear} firstFiscalQtr={props.firstFiscalQtr} />
            </Box>
            <Box id={'pdf-content-1'}>
                <SectionOne pillarId={props.pillarId} />
            </Box>
            <Box id={'pdf-content-2'}>
                <Box border={'1px solid #ccc'} boxShadow={'0 0 10px rgba(0, 0, 0, 0.1)'} p={'20px'} mt={'20px'} mb={'20px'}>
                    <SectionTwo pillarId={props.pillarId} />
                </Box>
            </Box>
            <Box id={'pdf-content-3'}>
                <Box border={'1px solid #ccc'} boxShadow={'0 0 10px rgba(0, 0, 0, 0.1)'} p={'20px'} mt={'20px'} mb={'20px'}>
                    <SectionThree pillarId={props.pillarId} />
                </Box>
            </Box>
        </Box>
    )
}

export default PDFContent