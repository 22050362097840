import React from 'react'
import numeral from 'numeral';

const NumberFormat = ({ number }) => {
    const formattedNumber = numeral(number).format('0,0');
    return (
        <>{formattedNumber}</>
    )
}

export default NumberFormat