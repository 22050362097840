import React, { useEffect, useState } from 'react';
import Styles from './viewpdf.module.css'
import SlidingPane from "react-sliding-pane";
import jsPDF from 'jspdf';
import pptxgen from 'pptxgenjs';
import html2canvas from 'html2canvas';
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Button, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure } from '@chakra-ui/react';
import PDFContent from './PDFContent/PDFContent';

const ViewPdf = (props) => {
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    const printPDF = async () => {
        try {
            const customPageWidth = 297; // A4 landscape width in mm
            const customPageHeight = 210; // A4 landscape height in mm
    
            const pdf = new jsPDF('l', 'mm', [customPageWidth, customPageHeight]);
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
    
            const headings = [
                '', // No heading for the first page
                'Executive Leader Reporting',
                'Impact Stories',
                'Executive Level Review',
            ];
    
            const footerText = 'Page';
            const sectionIds = ['pdf-content-0', 'pdf-content-1', 'pdf-content-2', 'pdf-content-3'];
    
            const headerHeight = 15; // Header height in mm
            const footerHeight = 10; // Footer height in mm
            const headingBgColor = '#062341'; // Background color for the heading
    
            // Temporarily hide excluded elements
            const excludedElement = document.getElementById('exclude1');
            const originalDisplay = excludedElement ? excludedElement.style.display : null;
            if (excludedElement) {
                excludedElement.style.display = 'none';
            }
    
            const scaleToFit = (imgWidth, imgHeight, maxWidth, maxHeight) => {
                const widthScaleFactor = maxWidth / imgWidth;
                let finalWidth = imgWidth * widthScaleFactor;
                let finalHeight = imgHeight * widthScaleFactor;
    
                if (finalHeight > maxHeight) {
                    const heightScaleFactor = maxHeight / imgHeight;
                    finalWidth = imgWidth * heightScaleFactor;
                    finalHeight = imgHeight * heightScaleFactor;
                }
                return { finalWidth, finalHeight };
            };
    
            for (let i = 0; i < sectionIds.length; i++) {
                const element = document.getElementById(sectionIds[i]);
                if (element) {
                    // Wait for all images within the element to be loaded
                    const images = element.querySelectorAll('img');
                    const loadPromises = Array.from(images).map((img) => new Promise((resolve) => {
                        if (img.complete) {
                            resolve();
                        } else {
                            img.onload = resolve;
                            img.onerror = resolve; // In case of image load failure
                        }
                    }));
    
                    // Wait for image loading
                    await Promise.all(loadPromises);
    
                    // Ensure element is visible and rendered
                    element.scrollIntoView();
                    await new Promise(resolve => setTimeout(resolve, 1000)); // Adjust if needed
    
                    const canvas = await html2canvas(element, { scale: 2, useCORS: true });
                    const imgData = canvas.toDataURL('image/png');
    
                    const { finalWidth, finalHeight } = scaleToFit(
                        canvas.width,
                        canvas.height,
                        pageWidth,
                        pageHeight - headerHeight - footerHeight
                    );
    
                    const xOffset = (pageWidth - finalWidth) / 2;
                    const yOffset = headerHeight + (pageHeight - headerHeight - footerHeight - finalHeight) / 2;
    
                    if (i > 0) {
                        pdf.addPage();
                    }
    
                    if (i === 0) {
                        pdf.setFillColor('#062341');
                        pdf.rect(0, 0, pageWidth, pageHeight, 'F');
                    } else {
                        const headerText = headings[i];
                        pdf.setFillColor(headingBgColor);
                        pdf.rect(0, 0, pageWidth, headerHeight, 'F');
    
                        pdf.setFont('Helvetica', 'bold');
                        pdf.setFontSize(14);
                        pdf.setTextColor('#FFFFFF');
    
                        const textHeight = pdf.getTextDimensions(headerText).h;
                        const textY = headerHeight / 2 + textHeight / 2;
    
                        pdf.text(headerText, pageWidth / 2, textY, { align: 'center' });
                    }
    
                    pdf.addImage(imgData, 'PNG', xOffset, yOffset, finalWidth, finalHeight);
    
                    pdf.setFontSize(10);
                    pdf.text(`${footerText} ${i + 1}`, pageWidth / 2, pageHeight - footerHeight / 2, { align: 'center' });
                } else {
                    console.error(`Element with ID ${sectionIds[i]} not found.`);
                }
            }
    
            if (excludedElement && originalDisplay !== null) {
                excludedElement.style.display = originalDisplay;
            }
    
            pdf.save('document.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };

    const downloadPPT = async () => {
        try {
            const pptx = new pptxgen();
            const elementIds = ['pdf-content-0', 'pdf-content-1', 'pdf-content-2', 'pdf-content-3'];
    
            // Define slide dimensions (width and height in inches)
            const slideWidthInches = 10;  // Slide width in inches
            const slideHeightInches = '100%'; // Slide height in inches
    
            let isFirstSlide = true; // Flag to identify the first slide
    
            for (const id of elementIds) {
                const element = document.getElementById(id);
    
                if (element) {
                    // Skip elements with the class 'exclude1'
                    if (element.classList.contains('exclude1')) {
                        console.log(`Skipping element with id "${id}" due to exclusion criteria.`);
                        continue;
                    }
    
                    try {
                        // Capture the element as a canvas
                        const canvas = await html2canvas(element, {
                            useCORS: true, // Enable cross-origin images
                            allowTaint: true // Allow cross-origin images
                        });
    
                        const imgData = canvas.toDataURL('image/png');
                        const slide = pptx.addSlide();
    
                        // Add background color to the first slide
                        if (isFirstSlide) {
                            slide.addShape(pptx.ShapeType.rect, {
                                x: 0,
                                y: 0,
                                w: slideWidthInches,
                                h: slideHeightInches,
                                fill: { color: '#062341' } // Background color
                            });
                            isFirstSlide = false; // After the first slide, set flag to false
                        }
    
                        // Get the canvas aspect ratio
                        const canvasWidth = canvas.width;
                        const canvasHeight = canvas.height;
                        const aspectRatio = canvasWidth / canvasHeight;
    
                        // Set image dimensions while maintaining aspect ratio
                        let imgWidth = slideWidthInches;
                        let imgHeight = imgWidth / aspectRatio;
    
                        // Adjust dimensions if image height exceeds slide height
                        if (imgHeight > slideHeightInches) {
                            imgHeight = slideHeightInches;
                            imgWidth = imgHeight * aspectRatio;
                        }
    
                        // Calculate positions to center the image
                        const xPos = (slideWidthInches - imgWidth) / 2;
                        const yPos = (slideHeightInches - imgHeight) / 2;
    
                        // Add image to slide
                        slide.addImage({
                            data: imgData,
                            x: xPos,
                            y: yPos,
                            w: imgWidth,
                            h: imgHeight
                        });
    
                    } catch (captureError) {
                        console.error(`Error capturing element with id "${id}":`, captureError);
                    }
    
                } else {
                    console.warn(`Element with id "${id}" not found.`);
                }
            }
    
            // Save the presentation file
            await pptx.writeFile('presentation.pptx');
            console.log('Presentation created successfully.');
    
        } catch (error) {
            console.error('Error creating presentation:', error);
        }
    };

    return (
        <>
            <Button className='buttonPrimary' position={'absolute'} right={'30px'} ref={btnRef} colorScheme="teal" onClick={onOpen}>
                View / Download
            </Button>
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
                closeOnOverlayClick={false}
            >
                <DrawerOverlay />
                <DrawerContent maxWidth="70%">
                    <DrawerCloseButton />
                    <DrawerHeader>View / Download</DrawerHeader>
                    <DrawerBody>
                        <PDFContent 
                            pillarId={props.pillarId} 
                            title={props.title}
                            firstFiscalYear={props.firstFiscalYear} 
                            firstFiscalQtr={props.firstFiscalQtr}
                        />
                    </DrawerBody>
                    <DrawerFooter className={Styles.footer}>
                        <Button mt="0"  mr={3} colorScheme="blue" onClick={printPDF}>
                            Download as PDF
                        </Button>
                        <Button mt="0"  mr={3} colorScheme="blue" onClick={downloadPPT}>
                            Download as PPT
                        </Button>
                        <Button variant="outline" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default ViewPdf;