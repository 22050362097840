import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from '../../MetricData/metricdata.module.css'
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import API from '../../../../Services/API';
import {Heading, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import { Table, Thead, Tbody, Tfoot, Tr, Th, Td, TableCaption, TableContainer,} from '@chakra-ui/react'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import Select from 'react-select';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Checkbox, 
    Stack,
} from '@chakra-ui/react'
import ApproveComponent from './ApproveComponent/ApproveComponent';
import RejectComponent from './RejectComponent/RejectComponent';
import LoaderSpinner from '../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const ExecutiveLevelReview = () => {
  const token = window.localStorage.getItem("accessToken")
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [elrSectionOne, setElrSectionOne] = useState()
  const [elrMetric, setElrMetric] = useState()
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [viewOneId, setViewOneId] = useState(null);
  const [impactStories, setImpactStories] = useState(null);
  const [elr, setElr] = useState(null);
  const [noData, setNoData] = useState(0);
  const editor = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null)
  const [newErrorMessage, setNewErrorMessage] = useState(null)

  useEffect(()=>{
    setIsLoading(true)
    API.get(`/executive-level-review`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response=>{
      setElrSectionOne(response.data)
      setPreview(response.data && response.data[0].banner)
      setViewOneId(response.data && response.data.id)
      setIsLoading(false)
      setNoData(0)
    }).catch(error=>{
      setNewErrorMessage(error.response.data.error)
      setIsLoading(false)
      setNoData(1)
    })
  },[])

  const getUpdatedPending = (metricTask) =>{
    setElrSectionOne(metricTask)
    setPreview(metricTask && metricTask[0].banner)
    setViewOneId(metricTask && metricTask.id)
    setIsLoading(false)
    setNoData(0)
  }

  const getErrorUpdate = (error) =>{
    setErrorMessage(error)
  }
  const filteredData = elrSectionOne && elrSectionOne.filter(item => item.approval_status === 'Approve' || item.approval_status === 'Rejected' || item.approval_status === 'Approved by Comms Team' || item.approval_status === 'Approved by Action Office');

  return (
    <>
    {isLoading ? <LoaderSpinner /> : null}
    {errorMessage && errorMessage !== null ? <Box p={'5px'}> {errorMessage && errorMessage} </Box>:null}
    {
        newErrorMessage && newErrorMessage ? <Box> {newErrorMessage} </Box> :
    <TableContainer>
      <Table size='sm'>
        <Thead>
          <Tr className={Styles.tr}>
            <Th className={Styles.th}>Period - Year</Th>
            <Th className={Styles.th}>Period - Qtr</Th>
            <Th className={Styles.th}>Executive level review </Th>
            <Th className={Styles.th}>SIO/Action Name</Th>
            <Th className={Styles.th}>Created On</Th>
            <Th className={Styles.th}>Created By</Th>
            <Th className={Styles.th}>Approval Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            filteredData && filteredData.length === 0 ? (
              <Tr>
                <Td colSpan="8">There is no data available.</Td>
              </Tr>
            ):(
              filteredData && filteredData.map((item, index)=>
                <Tr className={Styles.tr}>
                  <Td className={Styles.td}>
                    {item.fiscal_year}
                  </Td>
                  <Td className={Styles.td}>
                    {item.fiscal_quarter}
                  </Td>
                  <Td className={Styles.td}>
                    {item.label}
                  </Td>
                  <Td className={Styles.td}>
                    <Link to={`/action/${item.module_id}/${item.action_name}`}>
                      {item.action_name}
                    </Link>
                  </Td>
                  <Td className={Styles.td}>
                    {new Date(item.created_at).toLocaleDateString()}
                  </Td>
                  <Td className={Styles.td}>
                    {item.user}
                  </Td>
                  <Td className={Styles.td}>
                    {item.approval_status}
                  </Td>
                </Tr>
              )
            )
          }
        </Tbody>
      </Table>
    </TableContainer>
}
  </>
  )
}

export default ExecutiveLevelReview