import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from './createmetric.module.css'
import { Link, useParams } from 'react-router-dom'
import { Heading, Card, CardBody, Box, Image, Text, Input, useDisclosure, Textarea, Button, SimpleGrid, Stack, CardFooter, Select} from '@chakra-ui/react'
import { FormControl, FormLabel, Switch  } from '@chakra-ui/react'
import API from '../../../../Services/API';
import { PhoneIcon, AddIcon, WarningIcon, } from '@chakra-ui/icons'
import LoaderSpinner from '../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';


const CreateMetric = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });
    const [isLoading, setIsLoading] =useState(false)
    const editor = useRef(null);
    const [message, setMessage] = useState(false)
    const [pillarAction, setPillarAction] = useState([]);
    const [isDashboard, setIsDashboard] = useState(false)
    const [isAction, setIsAction] = useState(false)
    const [isPillar, setIsPillar] = useState(false)
    const [strategy, setStrategy] = useState(false)    
    const [isQbr, setIsQbr] = useState(false)
    const [isBoard, setIsBoard] = useState(false)
    const [isInterlock, setIsInterlock] = useState(false)
    const [isMap, setIsMap] = useState(false)
    const [allow, setAllow] = useState(false)
    const {id} = useParams();
    const [errorMessage , setErrorMessage] = useState(null) 

    useEffect(()=>{
        API.get(`/pillar?module_id=${id}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            API.get(`/action?pillar_id=${response.data.id}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then((response1) => {
                
                setPillarAction(response1.data)
                setIsLoading(false)
            }).catch(error => {

            })

            API.get(`/strategy?pillar_id=${response.data.id}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then((response1) => {
                
                setStrategy(response1.data)
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
        }).catch(error=>{
            setIsLoading(false)
        })
        
    },[id])


    const openSlidingPane = () => {
        setState({ isPaneOpen: true })
    }

    const metricdashboard = (event) =>{
        setIsDashboard(event.target.checked)
    }
    const metricactioncheck = (event) =>{
        setIsAction(event.target.checked)
    }
    const metricpillarcheck = (event) =>{
        setIsPillar(event.target.checked)
    }
    const metricqbr = (event) =>{
        setIsQbr(event.target.checked)
    }
    
    // const metricboard = (event) =>{
    //     setIsBoard(event.target.checked)
    // }

    const metricinterlock = (event) =>{
        setIsInterlock(event.target.checked)
    }

    const metricmap = (event) =>{
        setIsMap(event.target.checked)
    }
    
    const newMetric =  () => {
        if($('#metricname').val() === '' || $("#metricdatatype").val() === '' || $("#metrictype").val() === '' || $("#metricaggregate").val() === ''){
            if($('#metricname').val() === ''){
                $('#metricname').addClass('redBorder')
                $('#metricname').focus()
            }else{
                $('#metricname').removeClass('redBorder')
            }

            if($("#metricdatatype").val() === ''){
                $('#metricdatatype').addClass('redBorder')
                $('#metricdatatype').focus()
            }else{
                $('#metricdatatype').removeClass('redBorder')
            }

            if($("#metrictype").val() === ''){
                $('#metrictype').addClass('redBorder')
                $('#metrictype').focus()
            }else{
                $('#metrictype').removeClass('redBorder')
            }

            if($("#metricaggregate").val() === ''){
                $('#metricaggregate').addClass('redBorder')
                $('#metricaggregate').focus()
            }else{
                $('#metricaggregate').removeClass('redBorder')
            }


        }else{
            setIsLoading(true)
            const formData = new FormData()
            formData.append("name", $('#metricname').val())
            formData.append("description", $("#metricdescription").val())
            formData.append("datatype", $("#metricdatatype").val())
            formData.append("metrictype", $("#metrictype").val())
            formData.append("aggregate", 'last')
            formData.append("map", isMap)
            formData.append("pillar_id", props.pillarMetricId && props.pillarMetricId)
            formData.append("allow_export_externally", allow)
            formData.append("dashboard", true)
            formData.append("elr", isQbr)

            if($("#metricaction").val() !== 'Please select action'){
                formData.append("action_id", parseInt($("#metricaction").val()))
            }
            if($("#metricstrategy").val() !== 'Please select strategy'){
                formData.append("strategy", parseInt($("#metricstrategy").val()))
            }

            API.post(`/metric`, formData, {
                headers: {
                'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                setState({ isPaneOpen: false });
                API.get(`/metric?pillar_id=${props.pillarMetricId}`, {
                    headers: {
                    'Authorization': 'Bearer ' + token
                    }
                }).then(response3 => {
                    setIsLoading(false)
                    setMessage(true)
                }).catch(error=>{
                    setIsLoading(false)
                })
            }).catch(error =>{
                setIsLoading(false)
                if(error.response.data.name){
                    setErrorMessage(error.response.data && error.response.data.name[0])
                }else{
                    setErrorMessage('Apologies for the inconvenience, but an error has occurred. If this issue persists, please reach out to our support team at support@purposefy.com.')
                }


            })
        }
    }

    const onHandleChange = (event) =>{
        setAllow(event.target.checked)
    }


    return (
        <>
            {isLoading ? <LoaderSpinner /> : null }
            <Button leftIcon={<AddIcon />} colorScheme='teal' variant='solid' float={'right'} onClick={openSlidingPane}>
                Add Metric 
            </Button>
            {message ? <Box className={Styles.successMessage}> Created Metric successfully  </Box> : false }
            <SlidingPane
                className={Styles.slidingPane}
                overlayClassName="some-custom-overlay-class"
                isOpen={state.isPaneOpen}
                title="Create Metric"
                subtitle=""
                width="50%"
                onRequestClose={() => {
                    // triggered on "<" on left top click or on outside click
                    setState({ isPaneOpen: false });
                }}
            >
                {
                    errorMessage !== null ? <Box color={'red'}>
                        {errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()}
                    </Box> : null
                }
                <SimpleGrid columns={[1, 2]} spacing='20px' mt={'10px'}>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Name <text as='span' className='hilightStar'>*</text></FormLabel>
                            <Input type='text' id={'metricname'} mb={'0px'} errorBorderColor='crimson' />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Data Type <text as='span' className='hilightStar'>*</text></FormLabel>
                            <Select id={'metricdatatype'} mb={'0px'} errorBorderColor='crimson'>
                                <option value={''}> Please select data type </option>
                                <option value={'percent'}> Percent </option>
                                <option value={'number'}> Number </option>
                                <option value={'money'}> Money </option>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Metric Type <text as='span' className='hilightStar'>*</text></FormLabel>
                            <Select id={'metrictype'} mb={'0px'} errorBorderColor='crimson'>
                                <option value={''}> Please select metric type </option>
                                <option value={'operational'}> Operational </option>
                                <option value={'financial'}> Financial </option>
                                <option value={'impact'}> Impact </option>
                            </Select>
                        </FormControl>
                    </Box>
                    {/* <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Aggregate <text as='span' className='hilightStar'>*</text></FormLabel>
                            <Select id={'metricaggregate'} mb={'0px'} errorBorderColor='crimson'>
                                <option value={''}> Please select aggregate </option>
                                <option value={'sum'}> Sum </option>
                                <option value={'avg'}> Avg </option>
                                <option value={'max'}> Max </option>
                                <option value={'last'}> Last </option>
                            </Select>
                        </FormControl>
                    </Box> */}
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Select Action</FormLabel>
                            <Select id={'metricaction'} mb={'0px'}>
                                <option value={null}> Please select action </option>
                                {
                                    pillarAction && pillarAction.map(item =>
                                        item.type === 'action' ?
                                        <option value={item.id}> {item.name} </option> : null
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Select Strategy</FormLabel>
                            <Select id={'metricstrategy'} mb={'0px'}>
                                <option value={null}> Please select strategy </option>
                                {
                                    strategy && strategy.map(item =>
                                        <option value={item.id}> {item.name} </option>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Box>
                    <Box>

                    </Box>

                    
                    <Box>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel fontWeight={'bold'} htmlFor='metricqbr' mb='0' className={Styles.frmLabel}>
                                Executive Leadership Review
                            </FormLabel>
                            <Switch id='metricqbr' mt={'14px'} onChange={metricqbr} />
                        </FormControl>
                    </Box>
                    
                    <Box>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel fontWeight={'bold'} htmlFor='metricmap' mb='0' className={Styles.frmLabel}>
                                Map 
                            </FormLabel>
                            <Switch id='metricmap' mt={'14px'} onChange={metricmap} />
                        </FormControl>
                    </Box>
                    <Box>
                        <FormControl display='flex' alignItems='center' mt={'0px'}>
                                <FormLabel htmlFor='share_data_externally' mb='0' fontWeight={'bold'}>
                                    Share Data Externally
                                </FormLabel>
                            <Switch id='share_data_externally' mt={'10px'} onChange={onHandleChange}  />
                        </FormControl>
                    </Box>
                </SimpleGrid>
                <Box>
                    <FormControl>   
                        <FormLabel fontWeight={'bold'}> Description </FormLabel>
                        <JoditEditor
                            id={"metricdescription"}
                            ref={editor}
                            tabIndex={1} // tabIndex of textarea
                            onChange={newContent => {}}
                        />
                        <Button colorScheme='blue' onClick={newMetric} mt={'20px'} float={'right'}>Add Metric</Button>
                    </FormControl>
                </Box>
            </SlidingPane>
        </>
    )
}

export default CreateMetric