import React, { useState, useEffect, useRef, useMemo } from 'react';
import Styles from './datafilter.modue.css'
import { Link, useParams } from 'react-router-dom'
import API from '../../../../../../Services/API';
import {Box, SimpleGrid} from '@chakra-ui/react'
import Select from 'react-select'
import LoaderSpinner from '../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const DataFilter = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const {metricId} = useParams();

    const [period, setPeriod] = useState(null);
    const [status, setStatus] = useState(null);
    const [approval, setApproval] = useState(null);
    const [periodType, setPeriodType] = useState(null);

    
    const options = [
        ...(props.fiscalYearDetail && props.fiscalYearDetail.length > 0 ? props.fiscalYearDetail.map(year => ({ value: year.id, label: year.name, name: 'year' })) : []),
        ...(props.fiscalYearQtrDetail && props.fiscalYearQtrDetail.length > 0 ? props.fiscalYearQtrDetail.map(quarter => ({ value: quarter.id, label: quarter.name, name: 'qtr' })) : [])
    ];

    const statusOptions =[
        {value:'on-track', label:'On Track'},
        {value:'delay', label:'Delayed'},
        {value:'acceleration', label:'Acceleration'}
    ]

    const approvalOptions =[
        {value:'pending', label:'Pending'},
        {value:'approved', label:'Approved'},
        {value:'requirefollow-up', label:'Require Follow Up'},
        {value:'ingovernancereview', label:'In Governance Review'}
    ]

    const periodSelect = (selectedOption) => {
        setIsLoading(true);
        if (selectedOption) {
            setPeriod(selectedOption.value);
            setPeriodType(selectedOption.name);

            const queryParams = {
                metric_id: metricId,
            };

            if (selectedOption.name === 'year') {
                queryParams.fiscal_year = selectedOption.value;
            } else if (selectedOption.name === 'qtr') {
                queryParams.fiscal_qtr = selectedOption.value;
            }

            if (status !== null) {
                queryParams.status = status;
            }

            if (approval !== null) {
                queryParams.approval = approval;
            }

            const queryString = new URLSearchParams(queryParams).toString();
            const fullUrl = `/get-metric-data-fiscal-year?${queryString}`;

            API.get(fullUrl, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            })
            .then(response => {
                if (response.status === 200) {
                    props.filteredMetricData(response.data);
                } else {
                    console.error(`Unexpected response status: ${response.status}`);
                }
            })
            .catch(error => {
                console.error('Error fetching metric data:', error);
            })
            .finally(() => {
                setIsLoading(false);
            });
        } else {
            API.get(`/metric-data?metric_id=${metricId}`)
            .then(response=>{
                props.filteredMetricData(response.data)
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
        }
    };

    const statusSelect = (selectedOption) =>{
        setIsLoading(true)
        if (selectedOption) {
            setStatus(selectedOption.value)
            let selectedPeriod = period
            let selectedStatus = selectedOption.value
            let selectedApproval = approval
    
            let url = `/get-metric-data-fiscal-year`;
            const queryParams = {
                metric_id: metricId,
                status: selectedOption.value
            };
    
            if(period !== null){
                if (periodType === 'year') {
                    queryParams.fiscal_year = period;
                } else if (periodType === 'qtr') {
                    queryParams.fiscal_qtr = period;
                }
            }
            if (selectedApproval !== null) {
                queryParams.approval = selectedApproval;
            }
            const queryString = new URLSearchParams(queryParams).toString();
            const fullUrl = `${url}?${queryString}`;
            API.get(fullUrl, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                if (response.status === 200) {
                    props.filteredMetricData(response.data)
                    setIsLoading(false)
                } else {
                    console.error(`Unexpected response status: ${response.status}`);
                    setIsLoading(false)
                }
            }).catch(error => {
                console.error('Error fetching metric data:', error);
                setIsLoading(false)
            });
        }else {
            API.get(`/metric-data?metric_id=${metricId}`)
            .then(response=>{
                props.filteredMetricData(response.data)
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
        }
        
    }

    const approvalSelect = (selectedOption) =>{
        setIsLoading(true)
        if (selectedOption) {
            setApproval(selectedOption.value)
            let selectedPeriod = period
            let selectedStatus = status
            let selectedApproval = selectedOption.value
    
            let url = `/get-metric-data-fiscal-year`;
            const queryParams = {
                metric_id: metricId,
                approval: selectedOption.value
            };
    
            if(period !== null){
                if (periodType === 'year') {
                    queryParams.fiscal_year = period;
                } else if (periodType === 'qtr') {
                    queryParams.fiscal_qtr = period;
                }
            }
    
            if (selectedStatus !== null) {
                queryParams.status = selectedStatus;
            }
    
            
    
            const queryString = new URLSearchParams(queryParams).toString();
            const fullUrl = `${url}?${queryString}`;
    
            API.get(fullUrl, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then(response => {
                if (response.status === 200) {
                    props.filteredMetricData(response.data)
                    setIsLoading(false)
                } else {
                    console.error(`Unexpected response status: ${response.status}`);
                    setIsLoading(false)
                }
            }).catch(error => {
                console.error('Error fetching metric data:', error);
                setIsLoading(false)
            });
        }else {
            API.get(`/metric-data?metric_id=${metricId}`)
            .then(response=>{
                props.filteredMetricData(response.data)
                setIsLoading(false)
            }).catch(error=>{
                setIsLoading(false)
            })
        }
        

        
        
    }
    
    return (
        <>
            {isLoading ? <LoaderSpinner /> : null }
            <SimpleGrid columns={[1, 2, 3, 4]} spacing='40px' mb={'15px'}>
                {/* {metricId} */}
                <Box>
                    <Select
                        options={options}
                        placeholder={'Period (Fy/QTR)'}
                        onChange={periodSelect}
                        isClearable
                    />
                </Box>
                <Box>
                    <Select
                        options={statusOptions}
                        placeholder={'Filter by Status'}
                        onChange={statusSelect}
                        isClearable
                    />
                </Box>
                <Box>
                    <Select
                        options={approvalOptions}
                        placeholder={'Filter by Approval'}
                        onChange={approvalSelect}
                        isClearable
                    />
                </Box>
            </SimpleGrid>
        </>
        
    )
}

export default DataFilter