import React, { useState, useEffect } from 'react';
import Styles from '../../executiveleadershipreviews.module.css';
import {
  SimpleGrid, Box, GridItem, Stat, StatLabel, StatNumber,
  Spinner, Text, Flex
} from '@chakra-ui/react';
import Select from 'react-select';
import API from '../../../../../../../../Services/API';
import parse from 'html-react-parser';
import NumberFormat from '../../../../../../../Modules/NumberFormat/NumberFormat';

const MetricInfo = (props) => {
  const token = window.localStorage.getItem("accessToken");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [elrMetric, setElrMetric] = useState([]);
  const [fiscalYearList, setFiscalYearList] = useState([]);
  const [fiscalQtrList, setFiscalQtrList] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    setError(null);
    API.get(`/fiscal-year-detail`, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(response => {
      setFiscalYearList(response.data[0].fiscalyear);
      setFiscalQtrList(response.data[1].fiscalquarter);
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
      setError('Failed to fetch fiscal year and quarter details. Please try again.');
    });
  }, [props.pillarId]);

  const statusChange = (event) => {
    if (!event || !event.value) {
      setElrMetric([]);
      return;
    }

    const [prefix, value] = event.value.split('_'); // Split prefix and actual value

    setIsLoading(true);

    if (prefix === 'year') {
      API.get(`/elr-sum-metric?action=${props.pillarId}&fiscal_year=${value}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response => {
        setElrMetric(response.data);
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
        setError('Failed to fetch filtered metrics. Please try again.');
      });
    } else if (prefix === 'qtr') {
      API.get(`/elr-sum-metric?action=${props.pillarId}&fiscal_qtr=${value}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response => {
        setElrMetric(response.data);
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
        setError('Failed to fetch filtered metrics. Please try again.');
      });
    }
  };

  function extractFiscalYear(quarterString) {
    const [fiscalYear] = quarterString.split(" ");
    return fiscalYear;
  }

  // Flattened options for fiscal year and quarter in a single array
  const flattenedOptions = fiscalYearList
  .slice() // Clone the list to avoid mutating the original
  .reverse() // Reverse the order of fiscal years
  .reduce((acc, year) => {
    // Add fiscal year as an option
    acc.push({ value: `year_${year.id}`, label: year.name, name: 'year' });

    // Add quarters as options, matching fiscal years, in reverse order
    const quarterOptions = fiscalQtrList
      .filter(quarter => extractFiscalYear(quarter.name) === "FY" + year.name.slice(-2))
      .slice()
      .reverse() // Reverse the order of fiscal quarters
      .map(quarter => ({
        value: `qtr_${quarter.id}`,
        label: quarter.name,
        name: 'qtr'
      }));

    return acc.concat(quarterOptions); // Safely concatenate quarters
  }, []);

  return (
    <GridItem colSpan={3}>
      <Box p={'20px'}>
        <Box className={Styles.toggleSection} float={'right'}>
          <Box>
            {/* Status Filter Dropdown */}
            <Select
              onChange={statusChange}
              options={flattenedOptions}
              className={Styles.floatR}
              placeholder="Select a fiscal year or quarter"
              isClearable
              isLoading={isLoading} // Show loading indicator for select
            />
          </Box>
        </Box>
        <Box className='clearfix'></Box>

        <Box className={Styles.rightParent}>
          {/* Loading and Error States */}
          {isLoading && (
            <Flex justifyContent="center" alignItems="center" height="200px">
              <Spinner size="xl" />
            </Flex>
          )}
          {error && <Box color="red" mt="10px">{error}</Box>}
          {!isLoading && !error && elrMetric && elrMetric.length === 0 && (
            <Box mt="10px">No metrics found for the selected fiscal year or quarter.</Box>
          )}

          {/* Metric Data Display */}
          {!isLoading && !error && elrMetric && elrMetric.length > 0 && (
            <SimpleGrid columns={[1, null, 3]} spacing='10px'>
              {elrMetric.map((metric) => (
                <Box key={metric.id} className={Styles.loopBox}>
                  <Stat className={Styles.stat}>
                    <StatNumber className={Styles.StatNumber}>
                      {/* Display metric value with formatting */}
                      {metric.data_type === 'money' ? " $ " : metric.data_type === 'number' ? " # " : null}
                      <NumberFormat number={metric.value} />
                      {metric.data_type === 'percent' ? " %" : null}
                    </StatNumber>
                    <StatLabel className={Styles.StatLabel}>
                      <Text fontWeight={'bold'}> {metric.metric_name} </Text>
                      {/* Metric description */}
                      {metric.description === '<p><br></p>' ? 'No description added' : parse(metric.description)}
                    </StatLabel>
                  </Stat>
                </Box>
              ))}
            </SimpleGrid>
          )}
        </Box>
      </Box>
    </GridItem>
  );
};

export default MetricInfo;
