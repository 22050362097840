import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import Styles from '../../executiveleadershipreviews.module.css'
import {Heading,Select, SimpleGrid, Box, Grid, GridItem, Stat, StatLabel, StatNumber, StatHelpText, Card, FormControl, Switch, Flex, Badge,  CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup, FormLabel, Textarea } from '@chakra-ui/react'
import API from '../../../../../../../../Services/API';
//import Select from 'react-select'
import LoaderSpinner from '../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const FiscalYear = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [fiscalYearList, setFiscalYearList] = useState()
    const [fiscalQtrList, setFiscalQtrList] = useState()
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const [selectedFiscalQtr, setSelectedFiscalQtr] = useState(null);
    const [selectedFiscalYearList, setSelectedFiscalYearList] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [successMessage, setSuccessMessage] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(()=>{
        API.get(`/fiscal-year-detail`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setFiscalYearList(response.data[0].fiscalyear)
            setFiscalQtrList(response.data[1].fiscalquarter)
        }).catch(error=>{
            setIsLoading(false)
        })
    },[])

    const getFiscalYear = (event) => {
        setSelectedFiscalYear(event.target.value)
        const selectedOption = event.target[event.target.selectedIndex];
        
        const dataValue = selectedOption.dataset.value;
        const getYear = dataValue && dataValue.match(/\d{4}/)
        if(getYear) {
            setSelectedFiscalYearList("FY"+getYear[0].toString().substring(2))
            
        }
    }

    const getFiscalQtr = (event) =>{
        setSelectedFiscalQtr(event.target.value)
    }

    const saveAction = (event) =>{
        setIsLoading(true)
        const formData = new FormData()
        if(selectedFiscalYear !== null){
            formData.append('fiscal_year', selectedFiscalYear)
        }
        if(selectedFiscalQtr !== null){
            formData.append('fiscal_quarter', selectedFiscalQtr)
        }
        formData.append('id', parseInt(event.target.id))
        formData.append('action', parseInt(props.pillarId))

        if(props.slideType=== 'slide1'){
            API.put(`/elr-dashboard`, formData, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                API.get(`/elr-dashboard?action=${props.pillarId}`, {
                    headers: {
                      'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    props.slideOneUpdate(response.data)
                    setIsLoading(false)
                    setSuccessMessage("Updated changes successfully")
                }).catch(error=>{
                    setIsLoading(false)
                    setErrorMessage(error.response.data.msg)
                })
            }).catch(error=>{
                setIsLoading(false)
                setErrorMessage(error.response.data.msg)
            })
        }else if(props.slideType=== 'slide2'){
            API.put(`/impact-story`, formData, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                setIsLoading(false)
                API.get(`/impact-story?action=${props.pillarId}`, {
                    headers: {
                      'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    // props.getSecondSlideUpdate(response.data)
                    setIsLoading(false)
                    setSuccessMessage("Updated changes successfully")
                }).catch(error=>{
                    setIsLoading(false)
                    setErrorMessage(error.response.data.msg)
                })            
            }).catch(error=>{
                setIsLoading(false)
                
                setErrorMessage(error.response.data.msg)
            })
        }else if(props.slideType=== 'slide3'){
            console.log(props.id)
            API.put(`/executive-level-review`, formData, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                setIsLoading(false)
                API.get(`/executive-level-review?action=${props.pillarId}`, {
                    headers: {
                      'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    // props.getSecondSlideUpdate(response.data)
                    setSuccessMessage("Updated changes successfully")
                    setIsLoading(false)
                }).catch(error=>{
                    setIsLoading(false)
                })            
            }).catch(error=>{
                setIsLoading(false)
                setErrorMessage(error.response.data.msg)
            })
        }
    }

    const sortedFiscalYearList = fiscalYearList && fiscalYearList.sort((a, b) => a.name.localeCompare(b.name));
    const sortedFiscalQtrList = fiscalQtrList && fiscalQtrList.sort((a, b) => a.name.localeCompare(b.name));

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            {
                props.editable ? 
                <>
                    <Box p={'5px'}>
                        {successMessage !== null ? <Box color={'#00a0da'}> {successMessage} </Box> : null}
                        {errorMessage !== null ? <Box color={'red'}> {errorMessage && errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1).toLowerCase()} </Box> : null}
                        <FormLabel fontWeight={'600'}> Select Fiscal Year </FormLabel>
                        <Select id={'metricData_targetfiscalyear'} mb={'15px'} onChange={getFiscalYear}>
                            <option value={''}> Select fiscal year </option>
                            {
                                sortedFiscalYearList && sortedFiscalYearList.map(item => 
                                    <option value={item.id} data-value={item.name}> {item.name} </option>
                                )
                            }
                        </Select>
                        <Box className='clearfix'></Box>
                    </Box>
                    <Box p={'5px'}>
                        <FormLabel fontWeight={'600'}> Select Fiscal Qtr </FormLabel>
                        <Select id={'metricData_targetfiscalquarter'} mb={'15px'} onChange={getFiscalQtr}>
                            <option value={''}> Select fiscal quarter </option>
                            {
                                fiscalQtrList && fiscalQtrList.map(item =>
                                    selectedFiscalYearList === item.name.substring(0, 4) ? 
                                    <option value={item.id}> {item.name} </option> : null
                                )
                            }
                        </Select>
                        <Button className='buttonPrimary' float={'right'} id={props.id} mt={'10px'} onClick={saveAction}> Save </Button>
                        <Box className='clearfix'></Box>
                    </Box>
                </>: null
            }
        </>
    )
}

export default FiscalYear




