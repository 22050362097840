import React, { useState, useEffect, useRef, useMemo } from 'react';
import $ from 'jquery'
import { useParams, Link } from 'react-router-dom';
import Styles from './impactmetrics.module.css'
import API from '../../../../Services/API';
import {Heading, SimpleGrid, Box, Flex, Switch, Card, CardHeader, CardBody, CardFooter, Image, Text, Input, Button,Wrap,WrapItem, Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import AreaChartComponent from '../../../Modules/Charts/AreaChartComponent';
import LoaderSpinner from '../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import TabLoader from '../../../Widgets/CommonWidgets/TabLoader/TabLoader';
import { InfoOutlineIcon } from '@chakra-ui/icons'
import parse from 'html-react-parser';
import NumberFormat from '../../../Modules/NumberFormat/NumberFormat';
import DeleteMetric from '../../../../Pages/Dashboard/Metrics/MetricDashboard/ImpactMetrics/DeleteMetric/DeleteMetric';
import AddImpactMetric from './AddImpactMetric/AddImpactMetric';

const ImpactMetrics = (props) => {
  const token = window.localStorage.getItem("accessToken")
  const [isLoading, setIsLoading]  = useState(false)
  const [impactMetrics, setImpactMetrics] = useState()
  const {id} = useParams();
  const [message, setMessage] = useState()

  useEffect(() => {
    setIsLoading(true)
    props.metricType(`impact`)
    props.emptyMetric()
    if(props.selectedOption?.name && props.selectedOption.name ==='year'){
      API.get(`/metric-filter?pillar_id=${props.pillarId}&query=impact&fiscal_year=${props.selectedOption?.value && props.selectedOption.value}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response1=>{
        setImpactMetrics(response1.data)
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
      })
    }else if(props.selectedOption?.name && props.selectedOption.name ==='qtr'){
      API.get(`/metric-filter?pillar_id=${props.pillarId}&query=impact&fiscal_qtr=${props.selectedOption?.value && props.selectedOption.value}`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response1=>{
        setImpactMetrics(response1.data)
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
      })
    }else{
      API.get(`/metric?pillar_id=${props.pillarId}&query=impact`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(response1=>{
        setImpactMetrics(response1.data)
        setIsLoading(false)
      }).catch(error=>{
        setIsLoading(false)
      })
    }
  },[props.pillarId])
  
  const handleFlip = (event) => {
    $(event.target).closest('.parentBox').addClass('flipped')
  };
  const handleFlipBack = (event) => {
    $(event.target).closest('.parentBox').removeClass('flipped')
  };
  const currentYear = new Date().getFullYear();
  
  const getUpdate = (impactMetric) => {
    setImpactMetrics(impactMetric)
    setMessage(true)
  }

  return (
    <>
      {isLoading ? <TabLoader /> : null}
      {props.isLoading && props.isLoading ? <TabLoader /> : null}
      {
        <Box maxW={'940px'}>
          {
            props.editable ? 
              <Box>
                <AddImpactMetric pillarId={props.pillarId} getUpdate={getUpdate} /> 
              </Box>
            : null
          }
          {
            props.filterStatus && props.filterStatus === 1 ?              
            <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'}>
              {
                props.metric && props.metric.map(metric => 
                  <Box className={Styles.commonElementStyle} backgroundColor={metric.color_picker}>
                    {
                      props.editable ? <DeleteMetric id={metric.id} getUpdate={getUpdate} /> : null
                    }
                    
                    <Link to={`impactmetrics/${metric.id}/${metric.name}`}>
                      <Card maxW='100%' backgroundColor={'transparent'} p={'0px'} className={Styles.customCard}>
                        <CardHeader p={'0px'}>
                          <Flex spacing='4'>
                            <Flex flex='1' gap='2' alignItems='center' flexWrap='wrap'>
                              <Box display={'flex'}>
                                {
                                  metric.image ? <Image src={metric.image} w={'64px'} height={'64px'} alt={metric.name}/> : null 
                                }
                                
                                <Heading className={Styles.customHeadingNumber}  ml={'15px'}>{metric.key_fact !== null ? metric.key_fact : 0}</Heading>
                                <Box className='clearfix'> </Box>
                              </Box>
                              <Text className={Styles.customPara} width={'100%'}>{metric.name}</Text>
                            </Flex>
                          </Flex>
                        </CardHeader>
                      </Card>
                    </Link>
                  </Box>
                )
              }
            </SimpleGrid> : 
            <SimpleGrid columns={[1, 2, 3, 4]} spacing='20px' mt={'10px'} mb={'20px'}>
              {
                impactMetrics && impactMetrics.map(metric => 
                  <>
                    <Box className={Styles.commonElementStyle} backgroundColor={metric.color_picker}>
                      {
                        props.editable ? <DeleteMetric id={metric.id} getUpdate={getUpdate} /> : null
                      }
                      
                      <Link to={`impactmetrics/${metric.id}/${metric.name}`}>
                        <Card maxW='100%' backgroundColor={'transparent'} p={'0px'} className={Styles.customCard}>
                          <CardHeader p={'0px'}>
                            <Flex spacing='4'>
                              <Flex flex='1' gap='2' alignItems='center' flexWrap='wrap'>
                                <Box display={'flex'}>
                                  {/* <Image src={metric.image} w={'64px'} height={'64px'} alt={metric.name}/> */}
                                  {
                                    metric.image ? <Image src={metric.image} w={'64px'} height={'64px'} alt={metric.name}/> : null 
                                  }
                                  <Heading className={Styles.customHeadingNumber}  ml={'15px'}>{metric.key_fact !== null ? metric.key_fact : 0}</Heading>
                                  <Box className='clearfix'> </Box>
                                </Box>
                                <Text className={Styles.customPara} width={'100%'}>{metric.name}</Text>
                              </Flex>
                            </Flex>
                          </CardHeader>
                        </Card>
                      </Link>
                    </Box>

                    {/* <Box maxW='sm' borderWidth='1px' borderRadius='lg' className='parentBox'>
                      <Box className='frontCard'>
                        <InfoOutlineIcon onClick={handleFlip} className='frontFlipIcon' />
                        <Box className='clearfix'></Box>
                        <Link className={Styles.teamLink} to={`/metric/${metric.id}/${encodeURIComponent(metric.name)}`}>
                            <Box p={'10px'}>
                                <Heading as='h5' size='sm' className={Styles.metricTitle}>
                                    {metric.name}
                                </Heading>
                                <Box className='metricCount'>
                                        {
                                          metric.chart_value.result.filter((item) => item.fiscal_year === `FY${currentYear}`).map((item, index) => (
                                            <>
                                              {
                                                <> 
                                                  { 
                                                    metric.datatype === 'money' ? "$" : 
                                                    metric.datatype === 'number' ? "#" : null
                                                  } 
                                                  <NumberFormat number={item.metric_data} />
                                                  {
                                                    metric.datatype === 'percent' ? "%" : null
                                                  } 
                                                </>
                                              }
                                            </>
                                          ))
                                        }
                                      </Box>
                                <AreaChartComponent chartData={metric?.chart_value?.result} />
                            </Box>
                        </Link>
                      </Box>
                      <Box className='backCard'>
                        <InfoOutlineIcon onClick={handleFlipBack} className='frontFlipIcon' />
                        <Box className='clearfix'></Box>
                        {
                          metric.description === '<p><br></p>' ? 
                            <Box className='noDesc'> No description </Box>
                          : 
                          metric.description === null ? 'No description' : parse(metric.description)
                        }
                      </Box>
                    </Box> */}
                  </>
                )
              }
            </SimpleGrid>
          }
          {
            props.filterStatus && props.filterStatus === 1 ? 
            <>
              {props.metric.length === 0 ? <Box className='successInfoNew'> Data not available now. Please check back later. </Box> : null } 
            </> : 
            <>
              {impactMetrics?.length === 0 ? <Box className='successInfoNew'> Data not available now. Please check back later. </Box> : null } 
            </>
          }
        </Box>
      }
    </>
  )
}

export default ImpactMetrics